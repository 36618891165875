<div class="checkout account overviewView">
  <ul class="steps clearfix">
    <li class="overview" [ngClass]="{'active': identifier=='profile'}"><a [appEtsRouterLink]="[{'name':embeddedin, 'target': 'profile-profile'}]"><q></q><span>{{'ets-profile.accountOverview' | translate}}</span></a></li>
    <li class="customerData" [ngClass]="{'active': identifier=='customerdata'}"><a [appEtsRouterLink]="[{'name':embeddedin, 'target': 'profile-customerdata'}]" (click)="loadCustomerForm()"><q></q><span>{{'ets-profile.customerInformation' | translate}}</span></a></li>
    <li class="homepage"><a (click)="returnToHome()"><q></q><span>{{'basket.landingPage' | translate}}</span></a></li>
    <li class="logout"><a (click)="doLogout()"><q></q><span>{{'ets-profile.logout' | translate}}</span></a></li>
  </ul>
  <div class="etsCaptionWrapper">
    <h1 class="etsSiteCaption">{{'ets-profile.headline' | translate}}</h1>
  </div>
  <div class="twoBlocks m-t-40" *ngIf="identifier === 'profile'">
    <div class="box">
      <h3>{{'ets-profile.salutation' | translate}} {{login.loginDataValue.forename}} {{login.loginDataValue.surname}},</h3>
      <h2>{{'ets-profile.customerID' | translate}}: {{login.loginDataValue.id}}</h2>
    </div>
      <a class="etsBtn" (click)="doLogout()">{{'ets-profile.logout' | translate}}</a>
  </div>

  <div class="m-t-40" *ngIf="identifier === 'customerdata'">
    <div class="box">
      <ngx-spinner [showSpinner]="accountLoading.value" [fullScreen]="false" type="ball-clip-rotate" size="medium">
        <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
      </ngx-spinner>
      <h3>{{'ets-profile.userdataHeadline' | translate}}</h3>

      <form *ngIf="etsRegisterData" class="keepPlaceholder checkoutForm" (ngSubmit)="updateAddress($event)">
        <div class="line field-salutation" [ngClass]="{'error': !!registerErrors.salutation}">

          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.Salutation' | translate}}*</span>
          <select name="salutation" class="input active" required="" style="padding-top: 13px;">

            <option value="" disabled="">{{'userDataForm.Salutation' | translate}}*</option>
            <option *ngFor="let item of etsRegisterData.salutations" value="{{item.key}}" [selected]="item.key == login.loginDataValue.salutation">{{item.value}}</option>
          </select>
          <span class="errorMessage" *ngIf="!!registerErrors.salutation">{{registerErrors.salutation}}</span>
        </div>
        <div class="line field-title">

          <span class="placeHolder" style="opacity: 0;">{{'userDataForm.Title' | translate}}</span>
          <input type="text" autocomplete="disallow" name="title" value="{{login.loginDataValue.title}}" class="input" placeholder="{{'userDataForm.Title' | translate}}" style="padding-top: 0;">
        </div>
        <div class="line field-firstname" [ngClass]="{'error': !!registerErrors.forename}">

          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.Name' | translate}}*</span><input type="text" name="firstname" value="{{login.loginDataValue.forename}}" class="input active" placeholder="{{'userDataForm.Name' | translate}}*" required="" style="padding-top: 13px;">
          <span class="errorMessage" *ngIf="!!registerErrors.forename">{{registerErrors.forename}}</span>
        </div>
        <div class="line field-lastname" [ngClass]="{'error': !!registerErrors.surname}">

          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.Surname' | translate}}*</span><input type="text" name="lastname" value="{{login.loginDataValue.surname}}" class="input active" placeholder="{{'userDataForm.Surname' | translate}}*" required="" style="padding-top: 13px;">
          <span class="errorMessage" *ngIf="!!registerErrors.surname">{{registerErrors.surname}}</span>
        </div>
        <div *ngIf="addInfoDesc !== ''" class="line field-nameAddition">
          <span *ngIf="addInfoReq" class="placeHolder" style="opacity: 1;">{{addInfoDesc}}</span><input *ngIf="addInfoReq && !inputMaxLength" type="text" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 0px;">
          <input *ngIf="addInfoReq && inputMaxLength" type="text" maxlength="8" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 0px;">
          <span *ngIf="!addInfoReq" class="placeHolder" style="opacity: 0;">{{addInfoDesc}}</span><input *ngIf="!addInfoReq" type="text" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{addInfoDesc}}" style="padding-top: 0px;">
          <input *ngIf="!addInfoReq && inputMaxLength" type="text" maxlength="8" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 0px;">
        </div>
        <div *ngIf="addInfoDesc === ''" class="line field-nameAddition">
          <span *ngIf="addInfoReq" class="placeHolder" style="opacity: 1;">{{'userDataForm.AdditionalInformation'| translate}}</span><input *ngIf="addInfoReq && !inputMaxLength" type="text" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{'userDataForm.AdditionalInformation'| translate}}*" style="padding-top: 0px;">
          <input *ngIf="addInfoReq && inputMaxLength" type="text" maxlength="8" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 0px;">
          <span *ngIf="!addInfoReq" class="placeHolder" style="opacity: 0;">{{'userDataForm.AdditionalInformation'| translate}}</span><input *ngIf="!addInfoReq && !inputMaxLength" type="text" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{'userDataForm.AdditionalInformation'| translate}}" style="padding-top: 0px;">
          <input *ngIf="!addInfoReq && inputMaxLength" type="text" maxlength="8" autocomplete="disallow" name="nameAddition" value="{{login.loginDataValue.name3}}" class="input" placeholder="{{addInfoDesc}}*" style="padding-top: 0px;">
        </div>
        <div class="line field-streetName" [ngClass]="{'error': !!registerErrors.street}">
          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.Street' | translate}}*</span><input type="text" name="streetName" value="{{login.loginDataValue.street}}" class="input active" placeholder="{{'userDataForm.Street' | translate}}*" required="" style="padding-top: 13px;">
          <span class="errorMessage" *ngIf="!!registerErrors.street">{{registerErrors.street}}</span>
        </div>
        <div class="line field-streetNumber" [ngClass]="{'error': !!registerErrors.houseNumber}">
          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.HouseNo' | translate}}*</span><input type="text" name="streetNumber" value="{{login.loginDataValue.houseNumber}}" class="input active" placeholder="{{'userDataForm.HouseNo' | translate}}*" required="" style="padding-top: 13px;">
          <span class="errorMessage" *ngIf="!!registerErrors.houseNumber">{{registerErrors.houseNumber}}</span>
        </div>
        <div class="line field-zip" [ngClass]="{'error': !!registerErrors.zipCode}">
          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.ZIPCode' | translate}}*</span><input type="text" name="zip" value="{{login.loginDataValue.zipCode}}" class="input active" placeholder="{{'userDataForm.ZIPCode' | translate}}*" required="" style="padding-top: 13px;">
          <span class="errorMessage" *ngIf="!!registerErrors.zipCode">{{registerErrors.zipCode}}</span>
        </div>
        <div class="line field-city"[ngClass]="{'error': !!registerErrors.city}">
          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.City' | translate}}*</span><input type="text" name="city" value="{{login.loginDataValue.city}}" class="input active" placeholder="{{'userDataForm.City' | translate}}*" required="" style="padding-top: 13px;">
          <span class="errorMessage" *ngIf="!!registerErrors.city">{{registerErrors.city}}</span>
        </div>
        <div class="line field-country">
          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.Country' | translate}}*</span><select name="country" class="input active" required="" style="padding-top: 13px;">
          <option value="" disabled="">{{'userDataForm.Country' | translate}}*</option>
          <option *ngFor="let item of etsRegisterData.county" value="{{item.key}}" [selected]="item.key == login.loginDataValue.countryInternalKey">{{item.value}}</option>
        </select>
        </div>
        <div class="line field-areaCode">
          <span class="placeHolder" style="opacity: 0;">{{'userDataForm.AreaCode' | translate}}</span><input type="text" name="areaCode" value="{{login.loginDataValue.phoneAreaCode}}" class="input" placeholder="{{'userDataForm.AreaCode' | translate}}" style="padding-top: 0px;">
        </div>
        <div class="line field-phoneNumber">
          <span class="placeHolder" style="opacity: 0;">{{'userDataForm.Phonenumber' | translate}}</span><input type="text" name="phoneNumber" value="{{login.loginDataValue.phoneNumber}}" class="input" placeholder="{{'userDataForm.Phonenumber' | translate}}" style="padding-top: 0px;">
        </div>
        <div class="line field-email" [ngClass]="{'error': !!registerErrors.emailAddress}">
          <span class="placeHolder active" style="opacity: 1;">{{'userDataForm.Email' | translate}}*</span>
          <input type="email" name="email" value="{{login.loginDataValue.emailAddress}}"
            class="input active" placeholder="{{'userDataForm.Email' | translate}}*"
            required="" style="padding-top: 13px; text-transform: unset;">
          <span class="errorMessage" *ngIf="!!registerErrors.emailAddress">{{registerErrors.emailAddress}}</span>
        </div>
        <div class="line field-passwordchange">
          <label class="label"><br><h3>{{'pwChange.ChangeText' | translate}}:</h3></label>
        </div>
        <div class="line field-passwordOld">
          <span class="placeHolder" style="opacity: 0;">{{'pwChange.CurrentPassword' | translate }}</span><input [type]="(showOldPassword) ? 'text' : 'password'" autocomplete="new-passwordOld" data-form-name="passwordOld" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{'pwChange.CurrentPassword' | translate}}" name="passwordOld" style="padding-top: 0px;"><span title="{{ 'general.password.showPassword' | translate }}" (click)="showPassword('showOldPassword')" class="visibilityToggler"></span>
        </div>
        <div class="line field-password" [ngClass]="{'error': !!registerErrors.password}">
          <span class="placeHolder" style="opacity: 0;">{{'pwChange.NewPassword' | translate }}</span><input [type]="(showNewPassword) ? 'text' : 'password'" autocomplete="new-password" data-form-name="password" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{'pwChange.NewPassword' | translate}}" name="password" style="padding-top: 0px;"><span title="{{ 'general.password.showPassword' | translate }}" (click)="showPassword('showNewPassword')" class="visibilityToggler"></span>
          <span class="errorMessage" *ngIf="!!registerErrors.password">{{registerErrors.password}}</span>
        </div>
        <div class="line field-passwordRepeat" [ngClass]="{'error': !!registerErrors.passwordRepeat}">
          <span class="placeHolder" style="opacity: 0;">{{'pwChange.NewPasswordConfirm' | translate }}</span><input [type]="(showNewPasswordConf) ? 'text' : 'password'" autocomplete="new-passwordRepeat" data-form-name="passwordRepeat" value="" data-toggle-all="password" class="input password visibilityToggle" placeholder="{{'pwChange.NewPasswordConfirm' | translate}}" name="passwordRepeat" style="padding-top: 0px;"><span title="{{ 'general.password.showPassword' | translate }}" (click)="showPassword('showNewPasswordConf')" class="visibilityToggler"></span>
          <span class="errorMessage" *ngIf="!!registerErrors.passwordRepeat">{{registerErrors.passwordRepeat}}</span>
        </div>
        <div class="info">
          {{'userDataForm.formInfoText' | translate}}
        </div>
        <input style="margin-top: 2rem;" class="etsBtn submitButton" type='submit' value="{{'userDataForm.buttonSave' | translate}}">
      </form>
    </div>
  </div>
</div>
