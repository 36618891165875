<div *ngIf="!headerContain">
  <!-- ! PRODUCTION -->
  <div *ngIf="productive">
    <a (click)="(globalConfig.detail.useContinueShoppingUrl == 1 && globalConfig.detail.continueShoppingUrl.length > 0) ? navigateToSite(globalConfig.detail.continueShoppingUrl) : navigateToSite(partnerKey) ">
      <header class="headerImgWrapper" *ngIf="!logoHidden">
        <div class="etsWrapper clearfix">
          <div class="logoArea clearfix">
            <a class="logo" (show)="logoHidden"></a>
          </div>
        </div>
      </header>
    </a>
  </div>

  <!-- ! END PRODUCTION -->

  <!-- ! PREVIEW -->
  <div *ngIf="preview">
    <a *ngIf="preview" href="#" (click)="!!openRedirectPopUp($event)">
      <header class="headerImgWrapper">
        <div class="etsWrapper clearfix">
          <div class="logoArea clearfix">
            <a *ngIf="preview" class="logo" href="#" (click)="!!openRedirectPopUp($event)"></a>
          </div>
        </div>
      </header>
    </a>
  </div>

  <!-- ! ENDPREVIEW -->


  <!-- ? MAYBE DELETE BELOW => not neccessary?! -->

  <div *ngIf="!preview">
    <header *ngIf="(logoHidden && (globalConfig.detail.useContinueShoppingUrl != 1 && globalConfig.detail.continueShoppingUrl.length <= 0))" style="cursor: pointer;" [appEtsRouterLink]="null">
      <div class="etsWrapper clearfix">
        <div class="logoArea clearfix">
          <a class="logo"></a>
        </div>
      </div>
    </header>

    <ng-container *ngIf="(logoHidden && (globalConfig.detail.useContinueShoppingUrl == 1 && globalConfig.detail.continueShoppingUrl.length > 0) )">
      <a [href]="globalConfig.detail.continueShoppingUrl">
        <header style="cursor: pointer;">
          <div class="etsWrapper clearfix">
            <div class="logoArea clearfix">
              <a class="logo"></a>
            </div>
          </div>
        </header>
      </a>
    </ng-container>
  </div>
</div>
<!-- ! Vorläufiger Header => Contain Headers -->
<div *ngIf="headerContain">
  <div *ngIf="productive">
    <a style="position: relative;" (click)="(globalConfig.detail.useContinueShoppingUrl == 1 && globalConfig.detail.continueShoppingUrl.length > 0) ? navigateToSite(globalConfig.detail.continueShoppingUrl) : navigateToSite(partnerKey)">
      <header class="headerImgWrapper_contain">
        <div class="headerWrapper">
          <img class="headerImg_contain" id="headerImg_contain">
          <div class="etsWrapper etsWrapper_contain clearfix">
            <div class="logoArea_contain clearfix">
              <img class="logo_contain" href="#" (show)="logoHidden">
            </div>
          </div>
        </div>
      </header>
    </a>
  </div>

  <div *ngIf="preview">
    <a style="position: relative;" (click)="(globalConfig.detail.useContinueShoppingUrl == 1 && globalConfig.detail.continueShoppingUrl.length > 0) ? navigateToSite(globalConfig.detail.continueShoppingUrl) : navigateToSite(partnerKey)">
      <header class="headerImgWrapper_contain">
        <div class="headerWrapper">
          <img class="headerImg_contain">
          <div class="etsWrapper etsWrapper_contain clearfix">
            <div class="logoArea_contain clearfix">
              <img class="logo_contain" href="#">
            </div>
          </div>
        </div>
      </header>
    </a>
  </div>
</div>
