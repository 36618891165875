export const environment = {
  production: true,
  ETS_API_URL: 'https://partnershop.easyticket.de/api/partnershop/',
  ETS_MEDIA_URL: 'https://static.easyticket.de/files/partner/',
  LANGS_URL: 'https://static.easyticket.de/assets/translations/',
  STYLE_URL: 'https://static.easyticket.de/',
  PREVIEW_URL: 'https://webpartnerback.easyticket.de/partner-preview/index/',
  SEATMAP_URL: 'https://ticket.easyticket.de/phoenixshop&a_ident=1',
  MAINSHOP_URL: 'https://www.easyticket.de/'
};
