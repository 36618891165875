export class EtsLoginData {
  id: number;
  isPrivateIndividual: string;
  salutation: number;
  addressFormText: string;
  title: string;
  title2: string;
  forename: string;
  nameSuffix: string;
  surname: string;
  name3: string;
  name4: string;
  street: string;
  houseNumber: string;
  houseNumberAddition: string;
  zipCode: string;
  city: string;
  district: string;
  zipCodePostOfficeBox: string;
  locationPostOfficeBox: string;
  postOfficeBox: string;
  phoneCountryCode: string;
  phoneAreaCode: string;
  phoneNumber: string;
  phone2CountryCode: string;
  phone2AreaCode: string;
  phone2Number: string;
  faxCountryCode: string;
  faxAreaCode: string;
  faxNumber: string;
  fax2CountryCode: string;
  fax2AreaCode: string;
  fax2Number: string;
  emailAddress: string;
  email2Address: string;
  countryInternalKey: number;
  countryText: string;
  language: string;
  shippingPriority: string;
  birthday: string;
  ecFlag: number;
  blocked: boolean;
  note: string;
  note2: string;
  mobileCountryCode: string;
  mobileAreaCode: string;
  mobileNumber: string;
  mobile2CountryCode: string;
  mobile2AreaCode: string;
  mobile2Number: string;
  homepage: string;
  homepage2: string;
  taxId: string;
  taxNumber: string;
  guestLogin: boolean;
  emailAddressRepeat: string;
  oldPassword: string;
  newPassword: string;
  passwordRepeat: string;
}
