import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../base-component/base-component';
import { EtsComponentRouterService } from '../ets-component-router-service/ets-component-router.service';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import { EtsSearchService } from '../ets-search-service/ets-search-service';

@Component({
  selector: 'app-ets-search-results',
  templateUrl: './ets-search-results.component.html',
  styleUrls: ['./ets-search-results.component.css'],
})
export class EtsSearchResultsComponent extends BaseComponent implements OnInit {
  @Input() embedName: string;
  @Input() identifier: string;

  globalConfig: any;

  constructor(public searchService: EtsSearchService,
    public configService: EtsConfigService,
    public routerService: EtsComponentRouterService) {
    super();
  }

  ngOnInit(): void {
    this.configService.getShopIdentifierSubject().subscribe(() => {
      this.globalConfig = this.configService.getConfigObject();

      const urlParams = new URLSearchParams(
        window.location.search

      );

      urlParams.forEach((value, key) => {

        if (key === 'q') {
          this.searchService.setQueueTermValue = value;

          this.searchService.getSearchData(value).subscribe();
        }
      });
    })
  }
}
