import {Component, Input} from '@angular/core';
import {BaseRouterComponent} from '../base-component/base-router-component';

@Component({
  selector: 'app-ets-detail-outer',
  templateUrl: './ets-detail-outer.component.html',
})
export class EtsDetailOuterComponent extends BaseRouterComponent {
  @Input() identifier: string;
  @Input() showTitle: string;
  @Input() showHeadliner: string;
  @Input() showInfoAccordion: string;
  @Input() showTicketing: string;
}
