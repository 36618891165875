import {Component, Input, OnInit, HostListener, ViewEncapsulation} from '@angular/core';
import {EtsFullShopService} from '../ets-full-shop-service/ets-full-shop.service';
import {EtsCheckoutService} from '../ets-checkout-service/ets-checkout-service';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-ets-full-shop',
  templateUrl: './ets-full-shop.component.html',
  styleUrls: ['./ets-full-shop.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EtsFullShopComponent implements OnInit {
  @Input() identifier = '';


  sticky: boolean;
  logoHidden: boolean;
  globalConfig: any;
  showSearchBar: boolean;
  partnerKey: string;
  searchbarVisible: string;
  preview: boolean;
  productive: boolean;

  constructor(
    public service: EtsFullShopService,
    public checkout: EtsCheckoutService,
    public config: EtsConfigService,
    private location: Location,
  ){}

  ngOnInit(): void {

    this.globalConfig = this.config.getConfigObject();
    this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
      if (identifier !== null && identifier !== '') {
        this.logoHidden = this.globalConfig.misc.logoHidden;
        this.showSearchBar = this.globalConfig.misc.show_search;
        this.preview = this.config.getPreview();
        this.productive = !this.preview;

        if (this.showSearchBar)
        {
          this.searchbarVisible = 'visible';
        }
        else
        {
          this.searchbarVisible = 'hidden';
        }
        this.partnerKey = this.config.getShopIdentifier();

      }
    });
  }

  goBack(): void {
    this.location.back();
  }

  /**
   * Used in preview mode to open header url as new Window instead of redirecting directly to url
   *
   * @param e any
   */
  openRedirectPopUp(): void {
    if (this.globalConfig.detail.useContinueShoppingUrl == 1 && this.globalConfig.detail.continueShoppingUrl.length > 0) {
      window.open(this.globalConfig.detail.continueShoppingUrl, '_blank', 'toolbar=yes,top=500,left=500,width=800,height=800');
    } else {
      const design = document.getElementById('config').getAttribute('loaddesign');
      window.open( environment.PREVIEW_URL + this.partnerKey + '?design=' + design ,
        '_blank',
        'toolbar=yes,top=500,left=500,width=800,height=800'
      );
    }
  }

  @HostListener('window:scroll', ['$event'])
  navScroll(): void {
    const nav = document.getElementById('navbar');
    const banner = document.getElementsByTagName('header')[0];
    const height = banner.offsetHeight;

    if (window.pageYOffset > height) {
      nav.classList.add('sticky');
      nav.classList.remove('loosen');
    } else {
      nav.classList.remove('sticky');
      nav.classList.add('loosen');
    }
  }
}
