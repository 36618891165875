<div class="detailWidget">
  <section class="etsContentWrapper etsWrapper">
    <section class="widgetContentWrapper widgetWrapper">
    <ngx-spinner name="detailspinner" [fullScreen]="true" type="ball-clip-rotate" size="medium">
      <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
    </ngx-spinner>
    @if (!!details) {
      <div class="eventPage date">
        @if (details.errorText) {
          <h2>{{ details.errorText }}</h2>
        }
        @if (config.getCompleteShop() || config.getPreview()) {
          <button (click)="back()" style="margin-bottom: 2rem;" class="etsBtn detail">{{'btnBack' | translate}}</button>
        }
        <div class="etsCaptionWrapper">
          @if (isInputTrue(showTitle)) {
            <h1 class="etsDetailArtist etsSiteCaption">
              {{details.base.artist}} {{(headlineDate.length > 0) ? ' - ' + headlineDate : ''}}
            </h1>
          }
        </div>
        @if (isInputTrue(showHeadliner)) {
          <app-ets-headliner [identifier]="identifier" embedName="subComponent"></app-ets-headliner>
        }
        <!-- ! Only for Calender direct booking -->
        @if (config.getConfigObject().calendar.directBooking && config.getConfigObject().directbooking.showDescription) {
          <div class="shortDescriptionDirectBooking" [innerHTML]="details.headliner.shortDescription"></div>
          @if (details?.headliner?.eventLink !== '') {
            <a class="headlinerInfoLink" href="{{details?.headliner?.eventLink}}" target="{{details?.headliner?.eventLinkTarget}}">{{details?.headliner?.eventLinkText}}</a>
          }
        }
        @if (isInputTrue(showInfoAccordion) && details.infoBox) {
          <app-ets-accordion>
            @if (moreEventInfo) {
              <app-ets-accordion-child state="open" title="{{'ets-detail.showMore' | translate}}">
                <div [innerHTML]="moreEventInfo"></div>
              </app-ets-accordion-child>
            }
            @if (details.infoBox.artistHTML) {
              <app-ets-accordion-child title="{{'ets-detail.artist' | translate}}">
                <div [innerHTML]="details.infoBox.artistHTML"></div>
              </app-ets-accordion-child>
            }
            @if (details.infoBox.locationHTML) {
              <app-ets-accordion-child title="{{'ets-detail.eventLocation' | translate }}">
                <div [innerHTML]="details.infoBox.locationHTML"></div>
              </app-ets-accordion-child>
            }
          </app-ets-accordion>
        }
        @if (isInputTrue(showTicketing)) {
          <app-ets-ticket [embedName]="embeddedIn" [identifier]="identifier"></app-ets-ticket>
        }
        @if (details?.infoBox?.packageInfoText !== '') {
          <div class="packageInfoText" [innerHTML]="details?.infoBox?.packageInfoText"></div>
        }
      </div>
    }
    </section>
  </section>
</div>
