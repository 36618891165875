import { Component } from '@angular/core';

@Component({
  selector: 'app-ets-maintenance-page',
  templateUrl: './ets-maintenance-page.component.html',
  styleUrls: ['./ets-maintenance-page.component.css']
})
export class EtsMaintenancePageComponent {

}
