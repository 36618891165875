<div class="holder clearfix">
  <input type="text" placeholder="{{ 'search.searchBarPlaceholder' | translate }}" [value]="searchService.getQueueTermValue"
    class="searchBox"
    data-placeholder-mobile="Suche"
    (keydown.enter)="navigateToSearch()"
    (keyup)="onFieldChange($event)"
    (blur)="onBlur()" (focus)="onFocus()">
  <div class="etsButton etsBtn icon search" [appEtsRouterLink]="[{name: embedName, target: 'searchresult'}]"></div>
  <div class="searchFlyout" [class]="(searchVisible) ? '' : 'off'" data-match-size=".searchBox" data-match-plus-width="4">
    <h2 class="etsSiteCaption">{{ 'search.resultText' | translate: {value1: searchService.getSearchSubjectValue.length} }}</h2>
    <ng-container *ngIf="searchVisible">
      <ng-container *ngIf="searchResults.events && searchResults.events.length > 0">
        <div class="block">
          <ng-container *ngFor="let searchResult of searchResults.events">
              <a [appEtsRouterLink]="[{name: embedName, target: 'detail-' + searchResult.dateId}]" class="line clearfix" *ngIf="searchResult.dateId">
                <div class="image" [ngStyle]="{'background-image': 'url(' + searchResult.image + ')'}"></div>
                <div class="etsTitle">
                  {{searchResult.name}}
                </div>
                <div class="etsDate">
                  {{(!searchResult.hideEventTime) ? searchResult?.date : (searchResult?.date.split(',',2).join(','))}}
                </div>
              </a>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="searchResults.artists && searchResults.artists.length > 0">
        <ng-container *ngFor="let searchResult of searchResults.artists">
          <!-- ToDo: identifier probably wrong -->
          <a [appEtsRouterLink]="[{name: embedName, target: 'artist-' + searchResult.identifier}]" class="line clearfix" *ngIf="searchResult.identifier">
            <div class="image" [ngStyle]="{'background-image': 'url(' + searchResult.image + ')'}"></div>
            <div class="etsTitle">
              {{searchResult.name}}
            </div>
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="searchResults.vouchers && searchResults.vouchers.length > 0">
        <!-- ToDo: vouchers probably not needed -->
        <ng-container *ngFor="let searchResult of searchResults.vouchers">
          <a [appEtsRouterLink]="[{name: embedName, target: 'detail-' + searchResult.identifier}]" class="line clearfix" *ngIf="searchResult.identifier">
            <div class="image" [ngStyle]="{'background-image': 'url(' + searchResult.image + ')'}"></div>
            <div class="etsTitle">
              {{searchResult.name}}
            </div>
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="searchResults.lottery && searchResults.lottery.length > 0">
        <!-- ToDo: lottery probably not needed -->
        <ng-container *ngFor="let searchResult of searchResults.lottery">
          <a [appEtsRouterLink]="[{name: embedName, target: 'detail-' + searchResult.identifier}]" class="line clearfix" *ngIf="searchResult.identifier">
            <div class="image" [ngStyle]="{'background-image': 'url(' + searchResult.image + ')'}"></div>
            <div class="etsTitle">
              {{searchResult.name}}
            </div>
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="searchResults.location && searchResults.location.length > 0">
        <ng-container *ngFor="let searchResult of searchResults.location">
          <!-- ToDo: identifier probably wrong -->
          <a [appEtsRouterLink]="[{name: embedName, target: 'location-' + searchResult.identifier}]" class="line clearfix" *ngIf="searchResult.identifier">
            <div class="image" [ngStyle]="{'background-image': 'url(' + searchResult.image + ')'}"></div>
            <div class="etsTitle">
              {{searchResult.name}}
            </div>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
