import {Component, Input, OnInit} from '@angular/core';
import {EtsLoginService} from '../ets-login-service/ets-login-service';
import {BaseComponent} from '../base-component/base-component';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import {ToastrService} from 'ngx-toastr';
import {EtsCheckoutService} from '../ets-checkout-service/ets-checkout-service';
import {take} from 'rxjs/operators';
import {EtsRegisterDataErrors} from '../ets-checkout-service/ets-checkout-data';
import {BehaviorSubject} from 'rxjs';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import { TranslateService } from '@ngx-translate/core';
import { EtsBasketService } from '../ets-basket-service/ets-basket-service';
import { LogService } from '../services/LogService.service';

@Component({
  selector: 'app-ets-profile',
  templateUrl: './ets-profile.component.html',
  styleUrls: ['./ets-profile.component.css']
})
export class EtsProfileComponent extends BaseComponent implements OnInit {
  @Input() identifier: string;
  @Input() embeddedin: string;
  partner_id: number;
  addInfoDesc: string;
  addInfoReq: boolean;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showNewPasswordConf: boolean;
  // ! FOR BOSCH SHOP
  inputMaxLength: boolean;

  etsRegisterData: any;
  accountLoading: BehaviorSubject<boolean>;
  registerErrors: EtsRegisterDataErrors;

  constructor(
    public login: EtsLoginService,
    private router: EtsComponentRouterService,
    private toastr: ToastrService,
    private basket: EtsBasketService,
    public checkoutService: EtsCheckoutService,
    public config: EtsConfigService,
    public translate: TranslateService,
    private logService: LogService
  ) {
    super();
    this.inputMaxLength = false;
  }

  ngOnInit(): void {
    this.inputMaxLength = false;
    const urlParams = new URLSearchParams(
      window.location.search
    );

    this.accountLoading = new BehaviorSubject<boolean>(false);
    this.registerErrors = new EtsRegisterDataErrors();
    setTimeout(() => {
      this.config.getShopIdentifierSubject().subscribe((identifier: string) => {

        // ! All Inputs with Max-Length are for shop 333678 => TEMPORARY!!!
        if (this.config.getShopIdentifier() === 'e785beecc5448bc15507d5986855df4b') {
          this.inputMaxLength = true;
        }

        if (identifier !== '') {
          if (!this.identifier) {
            this.identifier = this.config.getConfigObject().detail.mainComponent;
          }
          this.partner_id = this.config.getConfigObject().misc.partner_id;
          this.addInfoDesc = this.config.getConfigObject().addressfield.addInfoDesc;
          this.addInfoReq = this.config.getConfigObject().addressfield.addInfoReq;
          this.login.isLoggedIn.subscribe(data => {
            this.accountLoading.next(true);
            if (data === false) {

              if (urlParams.toString().length === 0) {
                this.router.navigateEmbed([{name: this.embeddedin, target: 'checkout-login'}]);
              } else {
                urlParams.forEach((value, key) => {
                  if (key === this.router.generateUrlName(this.embedName)) {
                    if (value === 'checkout-passwordrecovery') {
                      this.router.navigateEmbed([{name: this.embeddedin, target: 'checkout-passwordrecovery'}]);
                    } else {
                      this.router.navigateEmbed([{name: this.embeddedin, target: 'checkout-login'}]);
                    }
                  }
                });
              }
            } else {
              this.loadCustomerForm();
            }
          });
        }
      });
    });

    this.basket.updateBasket().subscribe();
  }

  /**
   * Sends an logout request to the API
   *
   * @returns void
   */
  doLogout(): void {
    this.login.doLogout().subscribe(
      () => {
        localStorage.removeItem('ets-isGuestOrder');
        this.router.resetToHome();
      }, error => {
        console.log(error);
        this.logService.createLog({
          message: 'doLogout: Logout failed',
          apiresponse: error
        });
        if (error.status === 401) {
          this.login.deleteCustomerSession();
          this.router.resetToHome();
        } else {
          this.toastr.error(this.translate.instant('toastr.profile.genericError'));
        }
      }
    );
  }

  /**
   * Updates customer informations
   *
   * @param $event any
   * @returns void
   */
  updateAddress($event): void {
    this.accountLoading.next(true);
    this.registerErrors = new EtsRegisterDataErrors();
    const newAddress = this.login.loginDataValue;

    newAddress.salutation = $event.target.salutation.value;
    newAddress.title = $event.target.title.value;
    newAddress.forename = $event.target.firstname.value;
    newAddress.surname = $event.target.lastname.value;
    newAddress.nameSuffix = $event.target.nameAddition.value;
    newAddress.street = $event.target.streetName.value;
    newAddress.houseNumber = $event.target.streetNumber.value;
    newAddress.zipCode = $event.target.zip.value;
    newAddress.city = $event.target.city.value;
    newAddress.countryInternalKey = $event.target.country.value;
    newAddress.phoneAreaCode = $event.target.areaCode.value;
    newAddress.phoneNumber = $event.target.phoneNumber.value;
    newAddress.emailAddress = $event.target.email.value ?? '';
    newAddress.emailAddressRepeat = $event.target.email.value ?? '';
    newAddress.oldPassword = $event.target.passwordOld.value ?? '';
    newAddress.newPassword = $event.target.password.value ?? '';
    newAddress.passwordRepeat = $event.target.passwordRepeat.value ?? '';
    newAddress.name3 = $event.target.nameAddition.value;

    newAddress.emailAddress = newAddress.emailAddress.trim();
    newAddress.emailAddressRepeat = newAddress.emailAddressRepeat.trim();

    newAddress.newPassword = newAddress.newPassword.trim();
    newAddress.oldPassword = newAddress.oldPassword.trim();
    newAddress.passwordRepeat = newAddress.passwordRepeat.trim();

    let err = false;
    if (newAddress.forename === '' || newAddress.forename === null || newAddress.forename === 'undefined') {
      this.registerErrors.forename = this.translate.instant('errorMessage.register.forenameError');
      err = true;
    }
    if (newAddress.surname === '' || newAddress.surname === null || newAddress.surname === 'undefined') {
      this.registerErrors.surname = this.translate.instant('errorMessage.register.surnameError');
      err = true;
    }
    if (newAddress.street === '' || newAddress.street === null || newAddress.street === 'undefined') {
      this.registerErrors.street = this.translate.instant('errorMessage.register.streetError');
      err = true;
    }
    if (newAddress.houseNumber === '' || newAddress.houseNumber === null || newAddress.houseNumber === 'undefined') {
      this.registerErrors.houseNumber = this.translate.instant('errorMessage.register.housenumberError');
      err = true;
    }
    if (newAddress.zipCode === '' || newAddress.zipCode === null || newAddress.zipCode === 'undefined') {
      this.registerErrors.zipCode = this.translate.instant('errorMessage.register.zipCodeError');
      err = true;
    }
    if (newAddress.city === '' || newAddress.city === null || newAddress.city === 'undefined') {
      this.registerErrors.city = this.translate.instant('errorMessage.register.cityError');
      err = true;
    }
    if (newAddress.emailAddress === '' || newAddress.emailAddress === null || newAddress.emailAddress === 'undefined') {
      this.registerErrors.emailAddress = this.translate.instant('errorMessage.register.emailError');
      err = true;
    }
    if (newAddress.newPassword !== newAddress.passwordRepeat) {
      this.registerErrors.passwordRepeat = this.translate.instant('errorMessage.register.passwordRepeatError');
      err = true;
    }

    if (newAddress.newPassword && !newAddress.newPassword.match(/^[^äÄöÖüÜ]*$/i)) {
      this.registerErrors.password = 'Das Passwort darf keine Umlaute enthalten';
      err = true;
    }

    if (newAddress.passwordRepeat && !newAddress.passwordRepeat.match(/^[^äÄöÖüÜ]*$/i)) {
      this.registerErrors.passwordRepeat = 'Das Passwort darf keine Umlaute enthalten';
      err = true;
    }

    if (this.addInfoReq) {
      if (newAddress.nameSuffix === '') {

        if (this.addInfoDesc !== '') {
          this.toastr.error('Bitte füllen Sie das Feld: ' + this.addInfoDesc + ' aus');
        } else {
          this.toastr.error('Bitte füllen Sie das Feld "Zusatzinformationen" aus');
        }

        err = true;
      }
    }

    if (this.partner_id === 333763) {
      if (newAddress.nameSuffix === '') {
        this.toastr.error('Bitte geben Sie Ihre Porsche-Mitarbeiternummer ein.');
        err = true;
      }
    }

    if (err) {
      this.accountLoading.next(false);
      return;
    }

    this.login.updateCustomer(newAddress).subscribe(
      data => {
        this.accountLoading.next(false);
        if (data) {
          this.toastr.success(this.translate.instant('toastr.profile.contactUpdateSuccess'));
          this.login.setLocalStorageParameters(newAddress);
        }
      },
      error => {
        console.log(error);
        this.logService.createLog({
          message: 'updateAddress: Could not update customer informations',
          apiresponse: error,
          parameters: newAddress
        });
        if (error.status === 404) {
          switch (error.error.errorCode) {
            case 'P16101': // Porsche employee number invalid
              this.toastr.error(this.translate.instant('toastr.porsche.invalidNumber'));
              break;
            case 'P16102': // Porsche employeed number missing
              this.toastr.error(this.translate.instant('toastr.porsche.numberNotFound'));
              break;
            default:
              this.toastr.error(this.translate.instant('toastr.profile.contactUpdateError'));
              break;
          }
        } else if (error.status === 409) {
          this.toastr.error(this.translate.instant('errorMessage.checkout.emailAlreadyExistsError'));
        } else {
          this.toastr.error(this.translate.instant('toastr.profile.contactUpdateError'));
        }

        this.accountLoading.next(false);
      }
    );
  }

  /**
   * Loads all needed informations for customer data form
   *
   * @return void
   */
  loadCustomerForm(): void
  {
    this.accountLoading.next(true);
    this.checkoutService.loadFormDataRegister().pipe(
      take(1)
    ).subscribe(
      () => {
        this.etsRegisterData = this.checkoutService.getFormDataRegisterSubjectValue;
        this.accountLoading.next(false);
      },
      error => {
        this.logService.createLog({
          message: 'loadCustomerForm: Unable to load customer informations',
          apiresponse: error,
        });
        console.log(error);
        this.accountLoading.next(false);
        this.toastr.error(this.translate.instant('toastr.profile.dataLoadingError'));
      }
    );
  }

  /**
   * Redirects customer back to Homepage
   *
   * @returns void
   */
  returnToHome(): void
  {
    this.router.resetToHome();
  }

  /**
   * Shows/Hides value of specific password input field
   *
   * @param input string
   * @returns void
   */
  showPassword(input: string): void {
    switch (input) {
      case 'showOldPassword':
        this.showOldPassword = (this.showOldPassword) ? false : true;
        break;
      case 'showNewPassword':
        this.showNewPassword = (this.showNewPassword) ? false : true;
        break;
      case 'showNewPasswordConf':
        this.showNewPasswordConf = (this.showNewPasswordConf) ? false : true;
        break;
      default:
        break;
    }
  }
}
