<section class="widgetContentWrapper widgetWrapper">
  @if (globalConfig.infofield.showBodyInfoText && globalConfig.infofield.showBodyTextIndexOnly && globalConfig.infofield.infotextBody !== '') {
    <div class="bodyInfoText" [innerHTML]="globalConfig.infofield.infotextBody | allowHTML"></div>
  }
  <div class="etsCaptionWrapper">
    <h1 class="etsSiteCaption">{{'ets-listing.Events' | translate}}</h1>
  </div>

  <div #etsListingId class="eventList noBorder">
    <div *ngIf="loaded" class="flexTable listTable">
      <div class="etsContent clearfix sameSizeParent">
        @for (event of listing.events; track index) {
          @defer (on viewport) {
            <div class="line" [attr.data-eventid]="event.dateId" [attr.data-eventitle]="event.name">
              <div class="holder hover hover-link" [appEtsRouterLink]="[{name: embedName, target: 'detail-' + event.dateId}]">
                <div class="eventPicture">
                  @if (config.getConfigObject().misc.showEventImagesInLists == 1) {
                    @if (event.image !== '') {
                      <div class="image" [ngStyle]="{'background-image': 'url(' + event.image + ')', 'background-size': 'contain'}"></div>
                    } @else {
                      <div class="image" [ngStyle]="{'background-image': 'url(/frontend/assets/img/elements/logo_black.png)', 'background-size': 'contain'}"></div>
                    }
                  }
                </div>
                <div class="eventName" [ngStyle]="config.getConfigObject().misc.showEventImagesInLists == 0 && {'padding-top': 0}" data-name="eventName">
                  <div class="etsTitle">{{event.name}}</div>
                  <div class="subTitle" [innerHTML]="event.description"></div>
                </div>
                <div class="dateAndLocation" [ngStyle]="config.getConfigObject().misc.showEventImagesInLists == 0 && {'padding-top': 0}" data-name="dateAndLocation">
                  @if (!event?.hideDateFlag) {
                    @if (!event.showEventTime) {
                      <div class="date">{{event.date.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy, H:mm':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}} {{'ets-listing.Time' | translate}}</div>
                    } @else {
                      <div class="date">{{event.date.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}}</div>
                    }
                  }
                  <div [attr.data-eventlocation]="event.location" class="location">{{event.location}}</div>
                  @if (config.getConfigObject().misc.showPricesInLists == 1 && event.prices) {
                    <div class="prices">{{ event.prices }}</div>
                  }
                  <div class="actions clearfix">
                    <div class="ets-ticket-wrapper ets-ticket-wrapper-listing">
                      <svg class="ets-ticket-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1799.36 557.58"><path class="ets-ticket-path" d="m1728.05,454.87c0-39.4,31.92-71.35,71.31-71.39v-32.91c-39.43,0-71.39-31.96-71.39-71.39s31.96-71.39,71.39-71.39v-33.69c-39.37-.07-71.26-32-71.26-71.39s31.89-71.32,71.26-71.39V0H0v31.32s.04,0,.07,0c39.43,0,71.39,31.96,71.39,71.39S39.49,174.11.07,174.11c-.02,0-.04,0-.07,0v33.69c39.4.04,71.33,31.98,71.33,71.39S39.4,350.54,0,350.58v32.91s0,0,.01,0c39.43,0,71.39,31.96,71.39,71.39S39.44,526.26.01,526.26c0,0,0,0-.01,0v31.32h1799.36v-31.32c-39.39-.04-71.31-31.99-71.31-71.39Z" fill="#f2001c"/></svg>
                      @if (event.organizerId === '539') {
                        <ng-container>
                          @if (!event.notBookable) {
                            <div>{{'ets-listing.Tickets'| translate}}</div>

                          } @else {
                            <div class="soldOut">{{'ets-listing.tickets_soldout'| translate}}</div>
                          }
                        </ng-container>
                      } @else {
                        <ng-container>
                          <div>{{'ets-listing.Tickets'| translate}}</div>
                        </ng-container>
                      }
                    </div>
                    <div class="clearfix"></div>
                    @if (event.printHome) {
                      <div class="printAtHome" title="Mit print@home können Sie Ihre Tickets bis kurz vor Veranstaltungsbeginn auf unserer Website buchen und selbst ausdrucken. Es ist daher eine Alternative zum traditionellen Papierticket. Bitte beachten Sie, dass kein gemeinsamer Warenkorb aus print@home- und Papiertickets möglich ist. Hier müssen Sie eine gesonderte Bestellung aufgeben.">
                        {{'ets-listing.printAtHome' | translate }}
                      </div>
                    }

                    @if (config.getConfigObject().misc.showPackagesInList == 1 && event.packages.length > 0) {
                      <ng-container>
                        @for (package of event.packages; track index) {
                          <ng-container>
                            @if (package['price_text'] && package['price_text'].length > 0) {
                              <a class="link" [appEtsRouterLink]="[{name: embedName != 'subComponent' ? embedName : 'Page', target: 'detail-777' + package.package_id}]">{{package['price_text']}}</a>
                            } @else {
                              <a class="link" [appEtsRouterLink]="[{name: embedName != 'subComponent' ? embedName : 'Page', target: 'detail-777' + package.package_id}]">Dauerkarte erhältlich</a>
                            }
                          </ng-container>
                        }
                      </ng-container>
                    }
                  </div>
                </div>
              </div>
            </div>
          } @placeholder {
            <div>
              <span #moreLoaderId> </span>
              <div [ngClass]="{'moreLoader': true, 'off': !this.loadingInProgress}" class="moreLoader off"></div>
              <div class="etsBtn loadMore">{{'ets-listing.loadMore' |translate}}</div>
            </div>
          }

        }
        @if (listing.events && listing.events.length === 0) {
          <div>
            <div class="line">
              @if (!listing.errorText) {
                <h2 *ngIf="!listing.errorText">{{'ets-listing.NoEvents' | translate}}</h2>
              } @else {
                <h2 *ngIf="listing.errorText">{{ listing.errorText }}</h2>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <span #moreLoaderId> </span>
  <div [ngClass]="{'moreLoader': true, 'off': !this.loadingInProgress}" class="moreLoader off"></div>
  <div class="etsBtn loadMore">{{'ets-listing.loadMore' |translate}}</div>
</section>
