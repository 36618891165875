import {Component} from '@angular/core';
import {BaseRouterComponent} from '../base-component/base-router-component';

@Component({
  selector: 'app-ets-checkout-outer',
  templateUrl: './ets-checkout-outer.component.html',
})
export class EtsCheckoutOuterComponent extends BaseRouterComponent {

}
