export class EtsCouponData {
  active: boolean;
  required: boolean;
  description: string;
  cancelText: string;

  constructor() {
    this.active = false;
    this.required = false;
    this.description = '';
    this.cancelText = '';
  }
}
