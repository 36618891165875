import {Directive, HostListener, Input} from '@angular/core';
import {EtsComponentRouterService} from './ets-component-router.service';
import { SpecialrequestsService } from '../services/specialrequests.service';

@Directive({
  selector: '[appEtsRouterLink]'
})
export class EtsRouterLinkDirective {
  @Input() appEtsRouterLink: {name: string, target: string, base?: string}[]|null;

  constructor(private router: EtsComponentRouterService, private specialRequests: SpecialrequestsService) { }

  @HostListener('click', ['$event']) onClick(event: any): void {
    if (this.appEtsRouterLink == null) {
      this.router.resetToHome();
    } else {
      if (this.appEtsRouterLink[0].target.includes('checkout')) {
        if (!this.specialRequests.checkBasketItems()) {
          let newroute = this.appEtsRouterLink;
          newroute[0].target = 'basket-detail';
          this.router.navigateEmbed(newroute);
        } else {
          this.router.navigateEmbed(this.appEtsRouterLink);
        }
      } else {
        this.router.navigateEmbed(this.appEtsRouterLink);
      }
    }
    event.stopPropagation();
  }
}
