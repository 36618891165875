import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EtsConfigService {
  shopIdentifierSubject: BehaviorSubject<string>;
  shopIdentifier: string;
  preview: boolean;
  completeShop: boolean;
  public serverCookie: string = '';

  constructor(@Inject('ETS_API_URL') public apiUrl: string, private http: HttpClient) {
    this.initConfig();
    this.shopIdentifierSubject = new BehaviorSubject<string>('');
  }

  /**
   * Initialize config object
   *
   * @returns void
   */
  public initConfig(): void {
    if ((window as any).etsWidgetShopConfig === undefined || (window as any).etsWidgetShopConfig === null) {
      (window as any).etsWidgetShopConfig = {
        invoice: {
          cashier: 'web'
        },
        detail: {
          showTitle: '1',
          showHeadliner: '1',
          showInfoAccordion: '1',
          showTicketing: '1',
          returnHomeUrl: '',
          returnHomeRouting: '',
          mainComponent: 'page',
          shopSiteUrl: '',
          keepEventUrl: false,
          continueShoppingUrl: '',
          useContinueShoppingUrl: 0
        },
        misc: {
          showCalendar: 0,
          showPackagesInList: 0,
          logoHidden: 0,
          showEventImagesInLists: 0,
          showPricesInLists: 0,
          showSearch: 0,
          languageCode: 'de',
          selectedDesign: 'main',
          onlyBestPlace: 0,
          webshopGuestOrders: 0,
          webshopTimeFilterCancelText: '',
          partner_id: 0,
          headerContain: false,
          css: '',
          headerImage: '',
          logoImage: '',
          maintenance_mode_active: 0,
          maintenance_message: 'Aufgrund von aktuell stattfindenden Wartungsarbeiten steht Ihnen unsere Seite aktuell nicht zur Verfügung.'
        },
        ticketing: {
          showPackages: 1,
          showPackageInTicketWidget: 0,
          BtnPackageTicketWidgetText: 'Zur Dauerkarte',
          showPriceInHeadliner: 1,
          groupbestseat: 0,
        },
        footer: {
          useEtsAgbs: 0,
          textagb: '',
          textagb2: '',
          useEtsImprint: 0,
          textImprint: '',
          useEtsPrivacy: 0,
          textPrivacy: '',
          linkAGB: '',
          linkImprint: '',
          linkPrivacy: ''
        },
        calendar: {
          directBooking: 0,
          customDescription: 0,
          title: '',
          imageUrl: '',
          videoUrl: '',
          infoText: '',
          moreInfoText: '',
          calendarStartMonth: 'CURRENT_MONTH',
          highlightEvents: '',
          highlightCaption: '',
        },
        addressfield: {
          addInfoReq: 0,
          addInfoDesc: ''
        },
        infofield: {
          showHeaderInfoText: 0,
          showBodyInfoText: 0,
          showBodyTextIndexOnly: 0,
          infotextBody: '',
          infotextHeader: ''
        },
        checkout: {
          allowPromotioncodes: 0,
          useFinalPage: 0,
          finalPageText: '',
        },
        directbooking: {
          showDate: 0,
          showDateTime: 0,
          showDescription: 0,
          isDirectBookingEvent: 0
        }
      };
    }
    this.preview = false;
    this.completeShop = false;
  }

  public get getShopIdentifierSubjectValue(): string {
    return this.shopIdentifierSubject.value;
  }

  public getConfigObject(): any {
    return (window as any).etsWidgetShopConfig;
  }

  public setShopIdentifier(identifier: string): void {
    this.shopIdentifier = identifier;
    this.shopIdentifierSubject.next(identifier);
  }

  public setPreview(preview: boolean): void {
    this.preview = preview;
  }

  public getPreview(): boolean {
    return this.preview;
  }

  public setCompleteShop(completeshop: boolean) {
    this.completeShop = completeshop;
  }

  public getCompleteShop(): boolean {
    return this.completeShop;
  }

  // Funktioniert nur nach der Initialisierung der Config-Komponente
  public getShopIdentifier(): string {
    return this.shopIdentifier;
  }

  public getShopIdentifierSubject(): BehaviorSubject<string> {
    return this.shopIdentifierSubject;
  }

  public getPartnerSettings(shopIdentifier: string, selectedDesign: string): Observable<any> {
    let headers = new HttpHeaders({partnershopId: shopIdentifier, design: selectedDesign});

    if (this.serverCookie !== '') {
      headers = headers.append('X-Server', this.serverCookie);
    }

    return this.http.get(
      this.apiUrl + 'settings',
      {headers}
    ).pipe(
      take(1)
    );
  }

  public getCustomizedImages(shopIdentifier: string): Observable<any> {
    let headers = new HttpHeaders({partnershopId: shopIdentifier});

    if (this.serverCookie !== '') {
      headers = headers.append('X-Server', this.serverCookie);
    }
    return this.http.get(
      this.apiUrl + 'customizing/images',
      {headers}
    ).pipe(
      take(1)
    );
  }

  public getCookieByName(name: string ): string {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
  }
}
