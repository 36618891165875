import { Component, Input } from '@angular/core';

import {BaseRouterComponent} from '../base-component/base-router-component';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';

@Component({
  selector: 'app-ets-menu-buttons-outer',
  templateUrl: './ets-menu-buttons-outer.component.html',
})
export class EtsMenuButtonsOuterComponent extends BaseRouterComponent {
  @Input() mode: string;
  @Input() embedName: string;
  @Input() identifier: string;
  @Input() redirectbasket: string;
  @Input() redirectprofile: string;

  constructor(router: EtsComponentRouterService) {
    super(router);
  }
}
