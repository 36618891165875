import {Component, Input, OnInit} from '@angular/core';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import {EtsComponentRouterData} from '../ets-component-router-service/ets-component-router-data';
import {EtsConfigService} from '../ets-config-service/ets-config.service';

@Component({
  selector: 'app-ets-component-router',
  templateUrl: './ets-component-router.component.html'
})
export class EtsComponentRouterComponent implements OnInit {
  @Input() embedName: string;
  embedUrlName: string;
  currentRouteStructure: { [embedName: string]: EtsComponentRouterData};
  configData: any;

  constructor(private router: EtsComponentRouterService, private config: EtsConfigService) {
  }

  ngOnInit(): void {
    this.currentRouteStructure = null;
    this.configData = this.config.getConfigObject();
    this.embedUrlName = this.router.generateUrlName(this.embedName);
    this.router.getStructureBehaviorSubject().subscribe((routeStructure) => {
      this.currentRouteStructure = routeStructure;
    });
  }

  hasName(): boolean {
    if (this.embedName === undefined) {
      return false;
    } else if (this.embedName === null) {
      return false;
    }
    return true;
  }
}
