import {Component, Input, OnInit} from '@angular/core';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import {BaseComponent} from './base-component';

@Component({
  selector: 'app-base-component',
  template: ''
})
export class BaseRouterComponent extends BaseComponent implements OnInit {
  @Input() embedName: string;

  constructor(private router: EtsComponentRouterService) {
    super();
  }

  ngOnInit(): void {
    if (this.embedName !== 'subComponent') {
      this.router.registerEmbed(this.embedName);
    }
  }
}
