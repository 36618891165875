import {Component, DoCheck, Input, OnInit, ViewEncapsulation, /*ViewChild*/} from '@angular/core';
import { EtsDetailData } from '../ets-detail-service/ets-detail-data';
import {EtsDetailService} from '../ets-detail-service/ets-detail.service';
import {take} from 'rxjs/operators';
import {BaseComponent} from '../base-component/base-component';
import {EtsDetailPackageService} from '../ets-detail-package-service/ets-detail-package.service';
import {EtsDetailPackageData} from '../ets-detail-package-service/ets-detail-package-data';
import { Location } from '@angular/common';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import SwiperCore, {Pagination} from 'swiper';


SwiperCore.use([Pagination]);
@Component({
  selector: 'app-ets-headliner',
  templateUrl: './ets-detail-headliner.component.html',
  styleUrls: ['./ets-detail-headliner.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EtsDetailHeadlinerComponent extends BaseComponent implements OnInit, DoCheck {

  @Input() identifier: string;
  details: EtsDetailData|EtsDetailPackageData;
  loaded: boolean;
  activePhoto: number;
  oldIdentifier: string;
  isLoading: boolean;
  isPackage: boolean;
  globalConfig: any;
  completeShop: boolean;
  eventDescription: string;
  videoLink: string;
  externalContent: boolean;

  constructor(
    public detailService: EtsDetailService,
    public packageService: EtsDetailPackageService,
    public location: Location,
    public config: EtsConfigService,
  ) {
    super();
    this.detailService = detailService;
    this.loaded = false;
    this.isLoading = true;
    this.completeShop = true;
    this.externalContent = false;
  }

  ngOnInit(): void {
    this.checkForCookies();
    this.loadEventInformations();
    this.detectLanguage();
  }

  back(): void {
    this.location.back();
  }

  ngDoCheck(): void {
    if (this.identifier !== this.oldIdentifier) {
      this.ngOnInit();
    }
  }


  /**
   * Loads event related data
   *
   * @returns void
   */

  loadEventInformations(): void {
    this.isLoading = true;
    this.isPackage = false;
    if (this.identifier?.indexOf('777') !== 0) {
      this.detailService.loadDetailData(this.identifier).pipe(take(1)).subscribe(
        details => {
          this.details = null;
          this.isLoading = false;
          this.loaded = false;
          this.oldIdentifier = null;
          if (details !== null) {
            this.details = details;
            this.loaded = true;
            this.activePhoto = 0;
            this.oldIdentifier = this.identifier;
            if (details.headliner.eventVideo !== '') {
              const videoFrame = details.headliner.eventVideo.replace(/\\"/g, '"');
              const video = new DOMParser().parseFromString(videoFrame, 'text/html');
              this.videoLink = video.body.getElementsByTagName('iframe')[0].src;
            }
          }
        },
        error => {
          console.log(error);
          this.isLoading = false;
        });
    } else {
      this.isPackage = true;
      this.packageService.loadPackageDetailData(this.identifier).subscribe(details => {
          this.details = null;
          this.isLoading = false;
          this.loaded = false;
          this.oldIdentifier = null;
          if (details !== null) {
            this.details = details;
            this.loaded = true;
            this.activePhoto = 0;
            this.oldIdentifier = this.identifier;
          }
        },
        error => {
          console.log(error);
          this.isLoading = false;
        });
    }
  }

  /**
   * Checks if cookie exists
   *
   * @returns void
   */
  checkForCookies(): void {
    this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
      const cookie = this.config.getCookieByName('ets-cookie-preferences-' + this.config.getShopIdentifier()).split('|');

      for (const val of cookie) {
        if (val.split(':')[0] === 'external') {
          if (val.split(':')[1] === '1') {
            this.externalContent = true;
          }
        }
      }

      this.globalConfig = this.config.getConfigObject();
      if (identifier !== null && identifier !== '') {
        this.completeShop = this.config.getCompleteShop();
      }

      const urlParams = new URLSearchParams(
        window.location.search
      );
      if (urlParams.has('edit')) {
        setTimeout(() => {
          this.scrollToTickets();
        }, 1000);
      }
    });
  }

  /**
   * Detects which languages is used
   *
   * @returns void
   */
  detectLanguage(): void {
    if (this.globalConfig.misc.languageCode === 'en') {
      if (this.details.headliner.shortDescriptionEN !== undefined && this.details.headliner.shortDescriptionEN !== null
        && this.details.headliner.shortDescriptionEN !== '') {
        this.eventDescription = this.details.headliner.shortDescriptionEN;
      } else {
          this.eventDescription = this.details?.headliner?.shortDescription;
        }
    } else {
      this.eventDescription = this.details?.headliner?.shortDescription;
    }
  }

  /**
   * Scrolls page to ticketing widget
   *
   * @returns void
   */
  scrollToTickets(): void {
    var ticketBox = document.querySelector('.ticketBox');
    var headerOffset = 190;
    var elementPosition = ticketBox.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
}
