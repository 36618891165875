import {Inject, Injectable} from '@angular/core';
import {EtsTicketData} from './ets-ticket-data';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {EtsBasketService} from '../ets-basket-service/ets-basket-service';
import {EtsAddBasketBestSeat} from '../ets-basket-service/ets-basket-data';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogService } from '../services/LogService.service';
import { HttpHeaderService } from '../services/httpHeaderService.service';

@Injectable({
  providedIn: 'root'
})
export class EtsTicketService {

  ticketDataStorage: { [identifier: string]: EtsTicketData };
  subject: {[identifier: string]: BehaviorSubject<EtsTicketData>};

  public constructor(
    @Inject('ETS_API_URL') public apiUrl: string,
    private http: HttpClient,
    private config: EtsConfigService,
    private basket: EtsBasketService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private httpHeaderService: HttpHeaderService,
    private logService: LogService
  ) {
    this.ticketDataStorage = {};
    this.subject = {};
  }

  /**
   * Send request to API and returns event informations
   *
   * @param identifier string
   * @returns BehaviourSubject<EtsTicketData>
   */
  public loadTicketData(identifier: string): BehaviorSubject<EtsTicketData> {
    let eventType = 'events';
    if (identifier?.indexOf('777') === 0) {
      eventType = 'package';
      identifier = identifier.replace(/777/g, '-');
    }
    if (this.hasDetailData(identifier)) {
      return this.subject[identifier];
    } else {
      this.subject[identifier] = new BehaviorSubject<EtsTicketData>(null);
    }

    this.config.getShopIdentifierSubject().subscribe(conf => {
      if (conf) {
        let headers = this.httpHeaderService.getHttpHeaders();
        const promise = this.http.get(this.apiUrl + eventType + '/' + parseInt(identifier, 10), {headers}).toPromise();
        promise.then((data: any) => {
          this.createTicketData(data,identifier,eventType);
          this.subject[identifier].next(this.ticketDataStorage[identifier]);
        });

        promise.catch((reason: HttpErrorResponse) => {
          this.logService.createLog({
            message: 'Could not load ticket data',
            apiresponsee: reason,
            identifier: identifier,
            eventType: eventType
          });
          console.log(reason.message);
        });
      }
    });
    return this.subject[identifier];
  }

  /**
   * Sends request with bestSeats to API
   *
   * @param eventId string
   * @param bestSeats EtsAddBasketBestSeat[]
   * @returns Observable<boolean>
   */
  public addBestSeatToBasket(eventId: string, bestSeats: EtsAddBasketBestSeat[], embedName: string = ''): Observable<boolean> {
    if (eventId.indexOf('777') === 0) {
      eventId = eventId.replace(/777/g, '-');
    }
    const formData: any = new URLSearchParams();
    for (const bestSeat of bestSeats) {
      formData.set('quantityBestSeat[' + bestSeat.id + ']', bestSeat.amount.toString());
    }
    formData.set('eventDateId', eventId);
    const subject = new Subject<boolean>();

    this.basket.addToBasket(formData.toString(), eventId, embedName).subscribe( () => {
      this.basket.bc.postMessage('updateBasket');
      let eventime = JSON.parse(localStorage.getItem('ets-eventTime-' + this.config.getShopIdentifier() ));

      if (eventime === null || eventime === undefined) {
        eventime = {};
      }

      eventime[eventId] = { showEventTime: this.ticketDataStorage[eventId].showEventTime};

      localStorage.setItem('ets-eventTime-' + this.config.getShopIdentifier(), JSON.stringify(eventime));
      subject.next(true);
    },
    error => {
      this.logService.createLog({
        message: 'addBestSeatToBasket: Could not add bestseats to cart',
        apiresponse: error,
        eventid: eventId,
        bestseats: formData.toString()
      });
      this.basket.basketLoading.next(false);
      console.log(error);
      if (error.status > 400) {

        // NOTE(mgentner): Quick and dirty solution for Porsche(needs to be removed/changed asap)
        if (error.error.error === 'porsche_limit_reached') {
          this.toastr.error(error.error.errors, '');
        } else if (error.status === 406) {
          this.toastr.error(this.translate.instant('errorMessage.ticketing.quantityReachedError'));
        } else if (error.status === 409) {
          if (error.error.errorCode === '3hA35') {
            this.toastr.error(this.translate.instant('errorMessage.ticketing.differentShippingMethods'));
          } else if (error.error.errorCode === 'fC949') {
            this.toastr.error(error.error.errors);
          } else if (error.error.errorCode === '7Kt78') {
            this.toastr.error(this.translate.instant('errorMessage.ticketing.notEnoughSeats'));
          } else if (error.error.errorCode === 'EwhM9') { // errormessage for shop 333811 => Only event ticket numbers allowed
            this.toastr.error(error.error.errors, '', {timeOut: 10000});
          } else if (error.error.infoCode === 'lichter_5z9A9') {
            this.toastr.error('Tickets konnten nicht hinzugefügt, da mindestens ein Familien-Ticket benötigt wird.');
          } else {
            this.toastr.error(this.translate.instant('checkout.basket.reservError'));
          }

        } else {
          this.toastr.error( this.translate.instant('checkout.basket.reservError') );
        }
      }
      console.log(error);
      subject.next(false);
    });
    return subject.asObservable();
  }

  /**
   * Sends request with in seatmap selected event tickets to API
   *
   * @param eventId string
   * @param seatNumbers  number[]
   * @param priceGroups number[]
   * @returns Observable<boolean>
   */
  public addSeatmapToBasket(eventId: string, seatNumbers: number[], priceGroups: number[], embedName: string = ''): Observable<boolean> {
    if (eventId.indexOf('777') === 0) {
      eventId = eventId.replace(/777/g, '-');
    }
    const formData: any = new URLSearchParams();
    for (const values of seatNumbers) {
      formData.append('seatNumbers[]', values);
    }
    for (const values of priceGroups) {
      formData.append('priceGroups[]', values);
    }
    formData.set('eventDateId', eventId);
    const subject = new Subject<boolean>();

    this.basket.addToBasket(formData.toString(), eventId, embedName).subscribe( () => {
      this.basket.bc.postMessage('updateBasket');
      subject.next(true);
    },
    error => {
      this.logService.createLog({
        message: 'addSeatmapToBasket: Unable to add seatmap tickets to cart',
        apiresponse: error,
        eventid: eventId,
        seatnumbers: seatNumbers,
        pricegroups: priceGroups,
        formdata: formData.toString(),
      });
      console.log(error);
      this.basket.basketLoading.next(false);
      if (error.status > 400) {
        if (error.status === 406) {
          // Max seat number exceeded
          if (error.error.errorCode === '8uQ53') {
            this.toastr.error(this.translate.instant('errorMessage.ticketing.quantityReachedError'))
          }
        } else if (error.error.error === 'porsche_limit_reached') {
          this.toastr.error(error.error.errors, '');
        } else if (error.status === 409) {
          if (error.error.errorCode === '3hA35') {
            this.toastr.error(this.translate.instant('errorMessage.ticketing.differentShippingMethods'));
          } else if (error.error.errorCode === 'fC949') {
            this.toastr.error(error.error.errors);
          } else if (error.error.errorCode === '7Kt78') {
            this.toastr.error(this.translate.instant('errorMessage.ticketing.notEnoughSeats'));
          } else if (error.error.errorCode === 'EwhM9') { // errormessage for shop 333811 => Only event ticket numbers allowed
            this.toastr.error(error.error.errors, '', {timeOut: 10000});
          } else {
            this.toastr.error(this.translate.instant('checkout.basket.reservError'));
          }
        } else {
          this.toastr.error( this.translate.instant('checkout.basket.reservError') );
        }
      }
      subject.next(false);
    });
    return subject.asObservable();
  }

  public hasDetailData(search: string): boolean {
    return this.ticketDataStorage.hasOwnProperty(search);
  }

  /**
   * Create TicketDataObject.
   *
   * @param data any
   * @param identifier string
   * @param eventType string
   */
  public createTicketData(data: any, identifier: string, eventType: string): void
  {
    this.ticketDataStorage[identifier] = {
      tickets: [],
      shipping: [],
      ticketMemoText: '',
      ticketStatus: false,
      ticketSeatmap: false,
      showEventTime: false,
      organizerName: '',
      organizerId: '',
      eventDateNote: '',
      isBookable: false,
      hideBestseatBooking: false,
    };
    if (eventType === 'events') {
      if (data.data && data.data.booking) {
        this.ticketDataStorage[identifier].ticketStatus = data.data.booking.status !== 'closed';
        this.ticketDataStorage[identifier].ticketSeatmap = data.data.booking.seatmap;
        this.ticketDataStorage[identifier].showEventTime = (data.data.event.show_event_time === 1) ? true : false ;
        this.ticketDataStorage[identifier].organizerName = data.data.organizer_name;
        this.ticketDataStorage[identifier].organizerId = data.data.organizer_id;
        this.ticketDataStorage[identifier].hideBestseatBooking = data.data.hide_bestseat_booking ?? false;
        this.ticketDataStorage[identifier].eventDateNote = data.data.note ?? '';
        if (data && data.data && data.data.booking && data.data.booking.price_categories) {
          for (const key in data.data.booking.price_categories) {
            if (data.data.booking.price_categories.hasOwnProperty(key) && data.data.booking.price_categories[key].items) {
              for (const priceCategory of data.data.booking.price_categories[key].items) {
                if (priceCategory.seats_available_for_order > 0) {
                  this.ticketDataStorage[identifier].isBookable = true;
                }

                this.ticketDataStorage[identifier].tickets.push(
                  {
                    eventId: data.data.event_date_id ? data.data.event_date_id : 0,
                    eventDateId: priceCategory.id,
                    category: priceCategory.category_number,
                    categoryName: priceCategory.category_name,
                    upgrade: priceCategory.upgrade,
                    priceGroupName: priceCategory.price_group_name,
                    priceGroupId: priceCategory.price_group_id,
                    availabilityStatus: priceCategory.status,
                    seatGroup: priceCategory.seat_group,
                    blockIdentifier: priceCategory.block_identifier,
                    price: priceCategory.price,
                    maxNum: parseInt(priceCategory.max_contingent, 10),
                    maxSeats: parseInt(priceCategory.max_seats, 10),
                    color: priceCategory.color,
                    internalKey: priceCategory.internal_key,
                  }
                );
              }
            }
          }
          for (const key in data.data.booking.shipping_methods) {
            if (data.data.booking.shipping_methods.hasOwnProperty(key)) {
              this.ticketDataStorage[identifier].shipping.push(
                {
                  id: data.data.booking.shipping_methods[key].id,
                  title: data.data.booking.shipping_methods[key].title,
                  description: data.data.booking.shipping_methods[key].description,
                  price: data.data.booking.shipping_methods[key].price,
                  pricePlain: data.data.booking.shipping_methods[key].pricePlain,
                  type: data.data.booking.shipping_methods[key].type,
                }
              );
            }
          }
        }
      }
      if (data.data && data.data?.booking_info) {
        this.ticketDataStorage[identifier].ticketMemoText = data.data.booking_info ?? '';
      }
    } else {
      if (data.data && data.data.booking) {
        this.ticketDataStorage[identifier].ticketStatus = data.data.booking.status !== 'closed';
        this.ticketDataStorage[identifier].ticketSeatmap = data.data.booking.seatmap;
        this.ticketDataStorage[identifier].organizerName = data.data.organizer_name ?? '';
        this.ticketDataStorage[identifier].organizerId = data.data.event_dates[0].organizer_id ?? '';

        if (data && data.data && data.data.booking && data.data.booking.price_categories) {
          for (const key in data.data.booking.price_categories) {
            if (data.data.booking.price_categories.hasOwnProperty(key) && data.data.booking.price_categories[key].items) {
              for (const priceCategory of data.data.booking.price_categories[key].items) {
                if (priceCategory.seats_available_for_order > 0) {
                  this.ticketDataStorage[identifier].isBookable = true;
                }

                this.ticketDataStorage[identifier].tickets.push(
                  {
                    eventId: data.data.event_dates[0].event_date_id ? data.data.event_dates[0].event_date_id : 0,
                    eventDateId: priceCategory.id,
                    category: priceCategory.category_number ? priceCategory.category_number : '',
                    categoryName: priceCategory.category_name ? priceCategory.category_name : '',
                    upgrade: priceCategory.upgrade ? priceCategory.upgrade : '',
                    priceGroupName: priceCategory.price_group_name ? priceCategory.price_group_name : '',
                    priceGroupId: priceCategory.price_group_id ? priceCategory.price_group_id : '',
                    availabilityStatus: priceCategory.status ? priceCategory.status : '',
                    seatGroup: priceCategory.seat_group ? priceCategory.seat_group : '',
                    blockIdentifier: priceCategory.block_identifier ? priceCategory.block_identifier : '',
                    price: priceCategory.price ? priceCategory.price : '',
                    maxNum: parseInt(priceCategory.max_seats, 10),
                    maxSeats: parseInt(priceCategory.max_seats, 10),
                    color: priceCategory.color ? priceCategory.color : '',
                    internalKey: priceCategory.internal_key ? priceCategory.internal_key : key,
                  }
                );
              }
            }
          }
          for (const key in data.data.booking.shipping_methods) {
            if (data.data.booking.shipping_methods.hasOwnProperty(key)) {
              this.ticketDataStorage[identifier].shipping.push(
                {
                  id: data.data.booking.shipping_methods[key].id,
                  title: data.data.booking.shipping_methods[key].title,
                  description: data.data.booking.shipping_methods[key].description,
                  price: data.data.booking.shipping_methods[key].price,
                  pricePlain: data.data.booking.shipping_methods[key].pricePlain,
                  type: data.data.booking.shipping_methods[key].type,
                }
              );
            }
          }
        }
      }
      if (data.data && data.data?.booking_info) {
        this.ticketDataStorage[identifier].ticketMemoText = data.data.booking_info ?? '';
      }
    }
  }
}
