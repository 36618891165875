export class EtsTicketData {
  tickets: {
    eventId: string,
    eventDateId: string,
    category: string,
    categoryName: string,
    upgrade: number,
    priceGroupName: string,
    priceGroupId: number,
    availabilityStatus: number,
    seatGroup: string,
    blockIdentifier: string,
    price: number,
    maxNum: number,
    maxSeats: number,
    color: string,
    internalKey: number,
  }[];
  shipping: {
    id: number,
    title: string,
    description: string,
    price: string,
    pricePlain: number,
    type: string,
  }[];
  ticketStatus: boolean;
  ticketMemoText: string;
  ticketSeatmap: boolean;
  showEventTime: boolean;
  hideBestseatBooking: boolean;
  organizerName: string;
  organizerId: string;
  eventDateNote: string;
  isBookable: boolean;
}

export class EtsCategoryData {
  id: string;
  eventId: string;
  categoryNumber: string;
  categoryName: string;
  priceGroupName: string;
  priceGroupId: number;
  price: number;
  seatGroup: string;
  blockIdentifier: string;
  color: string;
  internalKey: number;
}

export class EtsPriceCategoryData {
  id: number;
  title: string;
  color: string;
  price: number;
  seatGroup: string;
  priceFormatted: number;
  priceGroups: {
    id: number,
    title: string,
    price: number,
    priceFormatted: number,
    active: boolean,
  }[];
  totalCount: string;
  connectedCount: string;
}

export class EtsSeatmapTooltipData {
  info: string;
  seatViewUrl: string;
}

export class EtsSeatmapOptions {
  evId: string;
  cType: string;
  cId: string;
  server: string;
  additionalRequestParams: string;
  priceCategories: any;
}

export class EtsSeatmapTickets {
  id: string;
  seatNumber: string;
  priceGroups: {
    id: number,
    title: string,
    price: number,
    priceFormatted: number,
    active: boolean,
  }[];
  price: number;
  priceGroupId: number;
  info: string;
  seatViewUrl: string;
}
