import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { EtsConfigService } from '../ets-config-service/ets-config.service';

@Component({
  selector: 'app-ets-header',
  templateUrl: './ets-header.component.html',
  styleUrls: ['./ets-header.component.css']
})
export class EtsHeaderComponent implements OnInit {
  @ViewChild('headerImg_contain') headerObject;
  globalConfig: any;

  preview: boolean;
  productive: boolean;
  logoHidden: boolean;
  headerContain: boolean;

  partnerKey: string;

  containHeaderImage: string;

  constructor(
    private config: EtsConfigService,
  ) {
    this.globalConfig = null;
    this.preview = false;
    this.productive = false;
    this.logoHidden = false;
    this.headerContain = false;
   }

  ngOnInit(): void {

    this.config.getShopIdentifierSubject().subscribe( () => {
      this.globalConfig = this.config.getConfigObject();
      this.logoHidden = this.globalConfig.misc.logoHidden;
      this.preview = this.config.getPreview();
      this.productive = !this.preview;
      this.headerContain = this.globalConfig.misc.headerContain;
      if (this.headerContain) {
        setTimeout(() => {
          this.assignImages('.headerImg_contain', this.globalConfig.misc.headerImage);
          if (!this.globalConfig.misc.logoHidden) {
            if (this.globalConfig.misc.logoImage !== undefined && this.globalConfig.misc.logoImage !== undefined
              && this.globalConfig.misc.logoImage !== '')
            {
              this.assignImages('.logo_contain', this.globalConfig.misc.logoImage);
            }
          } else {
            document.querySelector('.logo_contain').setAttribute('style', 'display: none;');
          }

        });
      }

      this.partnerKey = this.config.getShopIdentifier();
    });
  }

  /**
   * Sets images for Contain Mode Header in FullShop
   * @param selector string
   * @param imageURL string
   * @returns void
   */
  assignImages(selector: string, imageURL: string): void
  {
    document.querySelector(selector).setAttribute('style', 'content: ' + imageURL + ' !important;' );
  }


  /**
   * Navigates user to desired site
   *
   * @param url string
   */
  navigateToSite(url: string): void {
    window.location.href = url;
  }


  /**
   * Used in preview mode to open header url as new Window instead of redirecting directly to url
   *
   * @param e any
   */
   openRedirectPopUp(): void {
    if (this.globalConfig.detail.useContinueShoppingUrl == 1 && this.globalConfig.detail.continueShoppingUrl.length > 0) {
      window.open(this.globalConfig.detail.continueShoppingUrl, '_blank', 'toolbar=yes,top=500,left=500,width=800,height=800');
    } else {
      const design = document.getElementById('config').getAttribute('loaddesign');
      window.open( environment.PREVIEW_URL + this.partnerKey + '?design=' + design ,
        '_blank',
        'toolbar=yes,top=500,left=500,width=800,height=800'
      );
    }
  }

}
