export class EtsTelecashData {
  postData: {
    chargetotal: number,
    currency: string,
    oid: string,
    responseSuccessURL: string,
    responseFailURL: string,
    transactionNotificationURL: string,
    timezone: string,
    storename: string,
    txntype: string,
    mode: string,
    txndatetime: string,
    hash_algorithm: string,
    hash: string,
    paymentMethod: string,
    customerid: string,
    invoicenumber: string,
    authenticateTransaction: string,
    checkoutoption: string,
    language: string,

    // optional shipping data for paypal
    sname: string,
    saddr1: string,
    scity: string,
    sstate: string,
    scountry: string,
    szip: string,
  };
  url: string;
}

export class EtsFormDataRegister {
  salutations: {
    key: number,
    value: string
  }[];
  county: {
    key: number,
    value: string,
  }[];
}

export class EtsShippingData {
  shippingMethods: {
    id: number,
    title: string,
    description: string,
    price: string,
    pricePlain: number,
    type: string
  }[];
  personalisation: boolean;
}

export class EtsPaymentData {
  paymentMethods: {
    id: string,
    name: string,
    info: string,
    type: string,
    gateway: string,
    gatewayId: string,
    gatewayAuth: string,
    paymentId: number,
    mode: string,
    imageUrl: string,
  }[];
}

export class EtsPayment {
  id: string;
  name: string;
  info: string;
  type: string;
  gateway: string;
  gatewayId: string;
  gatewayAuth: string;
  paymentId: number;
  mode: string;
}

export class EtsShipping {
  id: number;
  title: string;
  description: string;
  price: string;
  pricePlain: number;
  type: string;
}

export class EtsPersonalisation {
  titleOfEvent: string;
  visitorNumber: string;
  data: {
    eventNos: string,
    seatNos: string,
    firstnames: string,
    lastnames: string,
    birthdays: string,
    streetname: string,
    streetnumber: string,
    zip: string,
    city: string
  };
}

export class EtsAGB {
  title: string;
  content: string;
}

export class EtsImpressum {
  title: string;
  content: string;
}

export class EtsDatenschutz {
  title: string;
  content: string;
}

export class EtsUeberUns {
  title: string;
  content: string;
}

export class EtsRegisterDataErrors {
  salutation?: string;
  title?: string;
  forename?: string;
  surname?: string;
  nameSuffix?: string;
  street?: string;
  houseNumber?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  countryInternalKey?: string;
  phoneAreaCode?: string;
  phoneNumber?: string;
  emailAddress?: string;
  emailAddressRepeat?: string;
  passwordOld?: string;
  password?: string;
  passwordRepeat?: string;
  company?: string;
  department?: string;
  additionalInfo?: string;
}
