import { Injectable } from '@angular/core';
import { EtsBasketService } from '../ets-basket-service/ets-basket-service';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import { ToastrService } from 'ngx-toastr';
// import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpecialrequestsService {

  constructor(
    private basketService: EtsBasketService,
    private configService: EtsConfigService,
    private toastrService: ToastrService,
  ) { }


  /**
   * Special requirement for Shop 333602
   * Checks if at least one ticket with pricegroup 'Musikschüler' and 1-2 tickets with pricegroup 'Begleitperson' are present.
   *
   * @returns boolean
   */
  public checkBasketItems333602(): boolean
  {
      // * Special requirement for Partnershop 333602
      if (this.configService.getShopIdentifierSubjectValue ==='f6e095bccdfde0e47071e89089229201') {

        // * Removed on 2024-10-28 (MGE)
        // if (!this.validateBasketItems('Musikschüler Hörgang-Konzerte', 2)) {
        //   this.toastrService.error('Buchung entspricht nicht den genannten Kriterien');
        //   return false;
        // }
      }

    return true;
  }

  /**
   * Helper function for specific partnershop requirements.
   */
  public checkBasketItems(): boolean
  {
    switch(this.configService.getShopIdentifierSubjectValue) {
      case '20051942ddcd456c29b88fddf5ad62ce':
        if (!this.checkNumberOfTickets(4)) {
          this.toastrService.error('Es müssen sich mindestens 4 Tickets in Ihrem Warenkorb befinden um die Bestellung abschließen zu können.', '', {disableTimeOut: true});
          return false;
        }
        break;
      case 'f6e095bccdfde0e47071e89089229201':
        // * Removed on 2024-10-28 (MGE)
        // if (!this.validateBasketItems('Musikschüler Hörgang-Konzerte', 2)) {
        //   this.toastrService.error('Buchung entspricht nicht den genannten Kriterien');
        //   return false;
        // }
        break;
      // case '063d1304dec40ac62368586941fd5f0c':
      //   if (environment.production === false) {
      //     if (!this.checkNumberOfTickets(4)) {
      //       this.toastrService.error('Es müssen sich mindestens 4 Tickets in Ihrem Warenkorb befinden um die Bestellung abschließen zu können.','', {disableTimeOut: true});
      //       return false;
      //     }
      //   }
      //   break;
      default:
        break;
    }

    // * No validation required for given partnershop.
    return true;
  }

  /**
   * Check ticket limitation requirements.
   * @param minTickets
   * @param maxTickets
   * @returns
   */
  private checkNumberOfTickets(minTickets: number = null, maxTickets: number = null): boolean
  {
    let counter = 0;

    for (let item of this.basketService.basketSubjectValue.items) {
      counter += Number(item.quantity);
    }

    if (minTickets === null && maxTickets !== null) {
      return counter <= maxTickets;
    } else if (minTickets !== null && maxTickets === null) {
      return counter >= minTickets;
    } else if (minTickets !== null && maxTickets !== null) {
      return counter <= maxTickets && counter >= minTickets;
    }

    return true;
  }


  /**
   * Helpermethod for Method checkBasketItems333602
   *
   * @returns boolean
   */
  // @ts-ignore
  private validateBasketItems(priceGroup: string, maxCount: number = null): boolean
  {
    // * Check if basket has specific pricegroup items.
    if (this.basketService.basketSubjectValue.items.find(i => i.priceGroups === priceGroup )) {
      let counter = 0;

      for (let item of this.basketService.basketSubjectValue.items) {
        if (item.priceGroups === 'Begleitperson Hörgang-Konzerte') {
          ++counter;
        }
      }

      // * Max
      if (counter === 0 || counter > maxCount) {
        return false;
      }
      return true;
    }
    return false;
  }
}
