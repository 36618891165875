<footer>
  <a class="topScroller off" [ngClass]="{'topScroller': true, 'on': showTopScroller, 'off': !showTopScroller}" style="bottom: 0px; " (click)="scrollToTop()">
    <div>
      <span>{{ 'ets-footer.goTop' | translate }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="left: 16px; width: 24px;">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
      </svg>
    </div>
  </a>
  <div class="bar">
    <div class="etsWrapper clearfix">
      <ul class="bottomNav clearfix">
        <li [hidden]="overlayAGBHidden"><app-ets-overlay title="{{'ets-footer.TOC' | translate }}" linkTitle="{{'ets-footer.TOC' | translate }}"><div style="font-size: 18px;" class="scroller contentText etsContentWrapper" [innerHTML]="agb.content | allowHTML"></div></app-ets-overlay></li>
        <li title="{{agb.title}}" [hidden]="!overlayAGBHidden" (click)="openURL(linkAGB)"><a>{{'ets-footer.TOC' | translate }}</a><div class="scroller contentText etsContentWrapper" [innerHTML]="agb.content | allowHTML"></div></li>
        <li [hidden]="overlayPrivacyHidden"><app-ets-overlay title="{{'ets-footer.Privacy' | translate }}" linkTitle="{{'ets-footer.Privacy' | translate }}"><div class="scroller contentText etsContentWrapper" [innerHTML]="datenschutz.content | allowHTML"></div></app-ets-overlay></li>
        <li title="{{datenschutz.title}}" [hidden]="!overlayPrivacyHidden" (click)="openURL(linkPrivacy)"><a>{{'ets-footer.Privacy' | translate }}</a><div class="scroller contentText etsContentWrapper" [innerHTML]="datenschutz.content | allowHTML"></div></li>
        <li [hidden]="overlayImprintHidden"><app-ets-overlay title="{{'ets-footer.legalNotice' | translate}}" linkTitle="{{'ets-footer.legalNotice' | translate}}"><div class="scroller contentText contentTextImprint etsContentWrapper " [innerHTML]="impressum.content | allowHTML"></div></app-ets-overlay></li>
        <li title="{{impressum.title}}" [hidden]="!overlayImprintHidden" (click)="openURL(linkImprint)"><a>{{'ets-footer.legalNotice' | translate}}</a><div class="scroller contentText contentTextImprint etsContentWrapper" [innerHTML]="impressum.content | allowHTML"></div></li>
        <li><a>© {{copyRightDate}} Easy Ticket Service</a></li>
      </ul>
    </div>
  </div>
</footer>
