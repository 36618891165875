import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ets-accordion',
  templateUrl: './ets-accordion.component.html',
  styleUrls: ['./ets-accordion.component.css']
})
export class EtsAccordionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
