import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {EtsBasketService} from '../ets-basket-service/ets-basket-service';
import {BaseComponent} from '../base-component/base-component';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import { TranslateService } from '@ngx-translate/core';
import { EtsCheckoutService } from '../ets-checkout-service/ets-checkout-service';
import { EtsCouponService } from '../ets-coupon-service/ets-coupon-service';
import { EtsCouponData } from '../ets-coupon-service/ets-coupon-data';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EtsLoginService } from '../ets-login-service/ets-login-service';

@Component({
  selector: 'app-ets-basket-detail',
  templateUrl: './ets-basket-detail.component.html',
  styleUrls: ['./ets-basket-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class EtsBasketDetailComponent extends BaseComponent implements OnInit {
  @Input() identifier: string;
  @Input() embeddedIn: string;
  globalConfig: any;
  shippingMethod: number;
  couponSettings: EtsCouponData;
  couponRequired: boolean;
  showEventTime: any;
  promotionCodeForm: FormGroup = new FormGroup({
    promotioncode: new FormControl(''),
  });

  public boschShop: boolean;
  public processing: boolean;

  constructor(
    public basket: EtsBasketService,
    public config: EtsConfigService,
    public router: EtsComponentRouterService,
    public translate: TranslateService,
    public checkout: EtsCheckoutService,
    public couponService: EtsCouponService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private loginService: EtsLoginService
  )
  {
    super();
    this.couponRequired = false;
    this.couponSettings = new EtsCouponData();
    this.shippingMethod = null;
    const shippingDetails = JSON.parse(localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()));
    if (shippingDetails !== undefined && shippingDetails !== null) {
      if (shippingDetails?.chosenShipping) {
        if (shippingDetails.chosenShipping[0].length > 0) {
          this.shippingMethod = shippingDetails.chosenShipping[0].id;
        }
      }
    }

    this.promotionCodeForm = this.formBuilder.group({
      promotioncode: ['', [Validators.minLength(4), Validators.maxLength(30)]]
    });
  }

  /**
   * Checks if a promotion code is needed
   *
   * @returns void
   */
  checkCouponRequirement(): void {
    this.couponService.getCouponCodeSettings().subscribe( (data) => {
      this.couponSettings = data['data'];
      this.couponRequired = data['data'].required && data['data'].active;
      this.couponService.etsCouponSettings = data['data'];
    },
    error => {
      console.log(error);
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.config.getShopIdentifierSubject().subscribe( (identifier) => {
      // ! Special Case Bosch shops.
      switch(identifier) {
        case '8284e02215089cb7d8cf019e5697c390':
        case '53a8edde17f2780485ccbdf81eb74e64':
        case 'f182d9ad06e9bda677d7a3046a0dc7ab':
        case '5306f1fd3425bbd1907c34d28fa82da3':
        case 'e26ba42994bef547cee9e624bb48dfe6':
          this.boschShop = true;
          break;
        default:
          this.boschShop = false;
          break;
      }
      this.globalConfig = this.config.getConfigObject();
      this.checkCouponRequirement();
      this.showEventTime = JSON.parse(localStorage.getItem('ets-eventTime-' + this.config.getShopIdentifier()));
    });
  }

  doCheckout(): void {
    this.basket.isCheckout = true;
  }

  /**
   * Redirects user to given URL
   * @param url string
   */
  redirectToUrl(url: string): void
  {
    window.location.href = url;
  }

  /**
   * Set customer promotioncode.
   *
   */
  redeemPromoCode(): void
  {
    if (this.promotionCodeForm.invalid || this.promotionCodeForm.value.promotioncode === '') {
      return;
    }

    this.basket.redeemPromotionCode(this.promotionCodeForm.value.promotioncode).subscribe( (data: boolean) => {
      this.basket.updateBasket().subscribe( () => {
        this.basket.basketLoading.next(false);
      });
      if (data) {
        this.toastrService.success(this.translate.instant('toastr.promotioncodes.redeemSuccess'));
      }
    },
    (err) => {
      this.basket.basketLoading.next(false);
      console.log(err);
      this.toastrService.error(this.basket.getPromotioncodeErrors(err , err.error.errorCode));
    });
  }

  /**
   * Removes set promotioncode from basket.
   */
  removePromoCode(): void
  {
    this.basket.cancelPromotionCode().subscribe( () => {
      this.basket.basketLoading.next(false);
      this.toastrService.success(this.translate.instant('toastr.promotioncodes.cancelSuccess'));
      this.basket.basketLoading.next(true);
      this.basket.updateBasket().subscribe( (data: boolean) => {
        if (data) {
          this.basket.basketLoading.next(false);
          this.promotionCodeForm.reset();
        } else {
          throw new Error('Could not update basket');
        }
      },
      (err: Error) => {
        console.log('Error:', err);
      });
    },
    (err: Error) => {
      console.log(err);
    });
  }

  public doGuestLogin(): void
  {
    this.processing = true;
    this.loginService.doGuestLogin().subscribe( () => {
        this.processing = false;
        this.router.navigateEmbed([{name: this.embeddedIn, target: 'checkout-address'}]);
    });
  }
}
