import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {EtsLoginService} from '../ets-login-service/ets-login-service';
import {EtsSearchData} from './ets-search-data';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EtsSearchService {
  private searchSubject: BehaviorSubject<EtsSearchData[]>;
  private searchLoading: BehaviorSubject<boolean>;
  private searchTerm: BehaviorSubject<string>;
  private queueTerm: BehaviorSubject<string>;

  public constructor(
    @Inject('ETS_API_URL') public apiUrl: string,
    private http: HttpClient,
    private config: EtsConfigService,
    private login: EtsLoginService,
  ) {
    this.searchSubject = new BehaviorSubject<EtsSearchData[]>([]);
    this.searchLoading = new BehaviorSubject<boolean>(false);
    this.searchTerm = new BehaviorSubject<string>('');
    this.queueTerm = new BehaviorSubject<string>('');
  }

  public get getSearchSubjectValue(): EtsSearchData[] {
    return this.searchSubject.value;
  }

  public get getSearchTermValue(): string
  {
    return this.searchTerm.value;
  }

  public get getQueueTermValue(): string
  {
    return this.queueTerm.value;
  }

  public set setQueueTermValue(term :string)
  {
    this.queueTerm.next(term);
  }

  /**
   * Send search request to API and returns elasticsearch results
   *
   * @param term string
   * @returns Observable<EtsSearchData[]>
   */
  public getSearchData(term: string): Observable<EtsSearchData[]> {
    this.searchLoading.next(true);
    this.searchTerm.next(term);
    const headers = this.getHttpHeaders();

    return this.http.get(this.apiUrl + 'search/' + term, { headers, params: {'exclusive': '1'} }).pipe(
      take(1),
      map((data: any) => {
        let search = [];
        if (data && data.status && data.status !== 'success') {
          this.searchLoading.next(false);
        }
        if (data && data.data) {
          for (const item of data.data) {
            search.push({
              id: item.id,
              title: item.title,
              title2: item.title2,
              title3: item.title3,
              subTitle: item.subTitle,
              imageUrl: item.imageUrl,
              date: item.date,
              location: item.location,
              hideEventTime : item.show_event_time,
            });
          }

          if (this.config.getShopIdentifierSubjectValue === '28d05739e21ac5d1a8f7a254f610235c' && term.toLowerCase() === 'vermeer') {
            search = search.sort(function(a: any,b: any): any {
              let date1 = String(a.date);
              date1 = date1.substring(date1.indexOf(',') +1, date1.length);
              date1 = String(date1.substring(0, date1.indexOf(',')));

              let date2 = String(b.date);
              date2 = date2.substring(date2.indexOf(',') +1, date2.length);
              date2 = String(date2.substring(0, date2.indexOf(',')));

              let dateObject1 = new Date(date1.trim().split('.').reverse().join('-'));
              let dateObject2 = new Date(date2.trim().split('.').reverse().join('-'));

              return dateObject1.getTime() < dateObject2.getTime() ? -1 : 1;
            });
          }
          this.searchLoading.next(true);
        }
        this.searchSubject.next(search);
        return search;
      })
    );
  }

  /**
   * Returns essential http headers for API-Calls
   *
   * @returns HttpHeaders
   */
  private getHttpHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('partnershopId', this.config.getShopIdentifier());

    if (this.login.loginTokenValue !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.login.loginTokenValue);
    }

    if (this.config.serverCookie !== '') {
      headers = headers.append('X-Server', this.config.serverCookie);
    }

    return headers;
  }
}
