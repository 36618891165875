import {Component, Input} from '@angular/core';
import {BaseRouterComponent} from '../base-component/base-router-component';

@Component({
  selector: 'app-ets-ticket-outer',
  templateUrl: './ets-ticket-outer.component.html',
})
export class EtsTicketOuterComponent extends BaseRouterComponent {
  @Input() identifier: string;
  @Input() embeddedIn: string;
}
