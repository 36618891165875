<section class="widgetContentWrapper widgetWrapper">
  <div class="etsCaptionWrapper" style="border-bottom: 1px solid var(--globalbordercolor)">
    <h1 *ngIf="searchService.getSearchTermValue !== ''" class="etsSiteCaption" style="border: none">{{ 'search.searchTextandHits' | translate: {value1: searchService.getSearchTermValue, value2: searchService.getSearchSubjectValue.length  } }}</h1>
    <h1 *ngIf="searchService.getSearchTermValue === ''" class="etsSiteCaption" style="border: none">{{ 'search.searchTermText' | translate }}</h1>
    <button class="etsBtn" (click)="routerService.resetToHome()">{{'basket.landingPage' | translate}}</button>
  </div>

  <div #etsListingId class="eventList noBorder">
    <div class="flexTable listTable">
      <div class="etsContent clearfix sameSizeParent">
        <div *ngFor="let event of searchService.getSearchSubjectValue" class="line">
          <div class="holder hover hover-link" [appEtsRouterLink]="[{name: embedName, target: 'detail-' + event.id}]">
            <div class="eventPicture">
              <div class="image" [ngStyle]="{'background-image': 'url(' + event?.imageUrl + ')'}"></div>

            </div>
            <div class="eventName" [ngStyle]="configService.getConfigObject().misc.showEventImagesInLists == 0 && {'padding-top': 0}" data-name="eventName">
              <div class="etsTitle">{{event?.title}}</div>
              <div class="subTitle" [innerHTML]="event?.subTitle"></div>
            </div>
            <div class="dateAndLocation" [ngStyle]="configService.getConfigObject().misc.showEventImagesInLists == 0 && {'padding-top': 0}" data-name="dateAndLocation">
              <div>{{(!event?.hideEventTime) ? event?.date : (event?.date.split(',',2).join(','))}}</div>

              <div class="location">{{event?.location}}</div>
              <div *ngIf="configService.getConfigObject().misc.showPricesInLists == 1 && event.prices" class="prices">{{ event?.prices }}</div>
              <div class="actions clearfix">
                <a class="tickets etsfont-ticket-button yellow etsBtn">
                  <span class="etsText">{{'ets-listing.Tickets'| translate}}</span>
                  <span class="icon"></span>
                </a>
                <div class="clearfix"></div>
                <div *ngIf="event.printHome" class="printAtHome" title="Mit print@home können Sie Ihre Tickets bis kurz vor Veranstaltungsbeginn auf unserer Website buchen und selbst ausdrucken. Es ist daher eine Alternative zum traditionellen Papierticket. Bitte beachten Sie, dass kein gemeinsamer Warenkorb aus print@home- und Papiertickets möglich ist. Hier müssen Sie eine gesonderte Bestellung aufgeben.">
                  {{'ets-listing.printAtHome' | translate }}
                </div>
                <ng-container *ngIf="configService.getConfigObject().misc.showPackagesInList == 1 && event?.packages?.length > 0">
                  <ng-container *ngFor="let package of event.packages">
                    <a *ngIf="package['price_text'] && package['price_text'].length > 0" class="link" [appEtsRouterLink]="[{name: embedName != 'subComponent' ? embedName : 'Page', target: 'detail-777' + package.package_id}]">{{package['price_text']}}</a>
                    <a *ngIf="!package['price_text'] || package['price_text'].length <= 0" class="link" [appEtsRouterLink]="[{name: embedName != 'subComponent' ? embedName : 'Page', target: 'detail-777' + package.package_id}]">Dauerkarte erhältlich</a>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="searchService.getSearchSubjectValue && searchService.getSearchSubjectValue.length === 0">
          <div class="line">
            <h2>{{ 'search.noResults' | translate }}</h2>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
