import {Component, Inject, Input, OnInit} from '@angular/core';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import {BaseComponent} from '../base-component/base-component';
import {DomSanitizer} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { EtsCouponService } from '../ets-coupon-service/ets-coupon-service';
import { EtsCouponData } from '../ets-coupon-service/ets-coupon-data';
import { EtsBasketService } from '../ets-basket-service/ets-basket-service';
import { EtsLoginService } from '../ets-login-service/ets-login-service';
import { init } from 'cookie-though';
import { EtsCheckoutService } from '../ets-checkout-service/ets-checkout-service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-ets-config',
  templateUrl: './ets-config.component.html',
  styleUrls: ['./ets-config.component.css'],
})
export class EtsConfigComponent extends BaseComponent implements OnInit {
  @Input() loadDesign: string;
  @Input() partnerKey: string;
  @Input() returnHomeUrl: string;
  @Input() returnHomeRouting: string;
  @Input() mainComponent: string;
  @Input() shopSiteUrl: string;
  @Input() keepEventUrl: boolean;
  @Input() preview: string;
  @Input() cookiebanner: string = 'true';
  selectedDesign: string;
  headerContain: boolean;
  customHeader: boolean;
  customLogo: boolean;
  promotionCodeSettings: EtsCouponData;
  globalConfig: any;

  constructor(private config: EtsConfigService,
              private router: EtsComponentRouterService,
              public sanitizer: DomSanitizer,
              public translate: TranslateService,
              public couponService: EtsCouponService,
              public route: ActivatedRoute,
              private basketService: EtsBasketService,
              private loginService: EtsLoginService,
              private checkoutService: EtsCheckoutService,
              private deviceService: DeviceDetectorService,
              @Inject('ETS_MEDIA_URL') public mediaUrl: string) {
    super();
  }

  initCookieBanner(): void {
    init({
      policies: [
        {
          id: 'essential',
          label: this.translate.instant('cookieconsent.policies.label'),
          description: this.translate.instant('cookieconsent.policies.description'),
          category: 'essential',
        },
        {
          id: 'external',
          label: this.translate.instant('cookieconsent.policies.external.label'),
          description: this.translate.instant('cookieconsent.policies.external.description'),
          category: 'social',
        }
      ],
      essentialLabel: this.translate.instant('cookieconsent.essentialLabel'),
      permissionLabels: {
        accept: this.translate.instant('cookieconsent.permissionLabels.accept'),
        acceptAll: this.translate.instant('cookieconsent.permissionLabels.acceptAll'),
        decline: this.translate.instant('cookieconsent.permissionLabels.decline')
      },
      cookiePreferenceKey: 'ets-cookie-preferences-' + this.config.getShopIdentifier(),
      header: {
          title: this.translate.instant('cookieconsent.header.title'),
          description: this.translate.instant('cookieconsent.header.description')
      },
      customizeLabel: this.translate.instant('cookieconsent.customizeLabel')
    });
  }

  validateBrowserVersion(): boolean {

    if (this.deviceService.deviceType.toLocaleLowerCase() === 'desktop' && this.deviceService.browser.toLocaleLowerCase() === 'safari' ) {
      const unsupportedVersion = '15.3';
      const usedVersion = this.deviceService.browser_version;
      const oldParts = unsupportedVersion.split('.');
      const newParts = usedVersion.split('.');
      for (let i = 0; i < newParts.length; i++) {
        const a = ~~newParts[i]; // parse int
        const b = ~~oldParts[i]; // parse int
        if (a > b) {
          return true;
        }
        if (a < b) {
          return false;
        }
      }
    }
    return true;
  }
  ngOnInit(): void {
    if (!this.validateBrowserVersion()) {
      let alertString = 'Diese Seite verwendet Funktionen, die nicht von Ihrer Browserversion unterstützt werden.';
      alertString += '\nBitte verwenden Sie einen anderen Browser um diese Seite nutzen zu können.';
      alertString += '\nVerwendeter Browser: ' + this.deviceService.browser + ' ' + this.deviceService.browser_version;
      alertString += '\nMindestanforderung: Safari 15.6';
      alert(alertString);
      return;
    }
    this.headerContain = false;
    this.promotionCodeSettings = new EtsCouponData();
    // * Needed to use components as widgets on websites
    if (environment.production) {
      const head = document.getElementsByTagName('HEAD')[0];
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = environment.STYLE_URL + 'styles.css';
      head.appendChild(link);
    }
    this.router.registerPopState();

    this.globalConfig = this.config.getConfigObject();
    if (this.returnHomeUrl) {
      this.globalConfig.detail.returnHomeUrl = this.returnHomeUrl;
    } else if (this.returnHomeRouting) {
      this.globalConfig.detail.returnHomeRouting = this.returnHomeRouting;
    } else {
      this.globalConfig.detail.returnHomeRouting = null;
    }
    if (this.mainComponent) {
      this.globalConfig.detail.mainComponent = this.mainComponent;
    }
    if (this.keepEventUrl) {
      this.globalConfig.detail.keepEventUrl = this.keepEventUrl;
    } else {
      // * Default for redirect on basket edit
      this.globalConfig.detail.keepEventUrl = true;
    }
    if (this.shopSiteUrl) {
      this.globalConfig.detail.shopSiteUrl = this.shopSiteUrl;
    }
    if (this.preview === 'true') {
      this.config.setPreview(true);
    }
    else {
      this.config.setPreview(false);
    }

    if (this.partnerKey !== undefined) {
      this.config.getPartnerSettings(this.partnerKey).subscribe((data: any) => {
        this.translate.use(data.data.webshop_language_code);

        // Special solution for partnershop "Landespresseball", german only
        if (data.data.partner_id === 333822 && data.data.webshop_language_code === 'de'){
          this.translate.setTranslation('de', {
              couponCodes: {
                errorMessage: {
                  invalidCouponCode: 'Das von Ihnen eingegebene Passwort ist ungültig oder wurde bereits verwendet.',
                  validCouponCode: 'Gültiges Passwort eingegeben.'
                },
                headline: 'Passwort benötigt',
                couponInfo: 'Um mit der Bestellung fortzufahren, benötigen Sie ein Passwort.',
                couponInfo2: 'Bitte geben Sie Ihr Passwort ein:',
                btnConfirm: 'Passwort bestätigen',
                placholderInput: 'Passwort'
              }
            },
            true);
        }

        this.config.getCustomizedImages(this.partnerKey).subscribe((images: any) => {

          this.setPartnerShopSettings(data);
          this.setInfoTextSettings(data);
          this.setCalendarSettings(data);
          this.setDirectBookingSettings(data);

          const urlParams = new URLSearchParams(
            window.location.search
          );

          urlParams.forEach((value, key) => {
            if (key === 'design') {
              this.loadDesign = value;
            }

          });

          if (document.getElementById('config')) {
            if (document.getElementById('config').hasAttribute('loaddesign')) {
              this.selectedDesign = document.getElementById('config').getAttribute('loaddesign');
              this.globalConfig.misc.selectedDesign = this.selectedDesign;
            }
          }

          if (this.loadDesign && this.loadDesign.length > 0 && data.data.css && data.data.css.length > 0) {
            const regexDesign = new RegExp('\\/\\*Design:' + this.loadDesign + '!.*Design-Ende:' + this.loadDesign + '\\*\\/', 'gs');
            let outputCss = '';
            let imageCss = '';
            const designCss = data.data.css.match(regexDesign);

            if (designCss) {
              if (!designCss[0].match(/ets-shop .etsShop.header[ ]*?{.*?background-image:[ ]*none.*?}?/)) {

                // * Validate if Header is in contain mode
                if (designCss[0].match(/.etsShop header{.*background-size:contain/gm)) {
                  this.globalConfig.misc.headerContain = true;

                  // * For Headers using the "contains" picture mode => Different Header structure needed
                  // * Regex for searching HeaderImage URL and LogoImage URL
                  const regex = /.*?((?<CSSClass>(etsShop\sheader(\s.logo)?))[\s\S]*?)*(?<UrlLogoImageFile>((https?:)[\S]*?))(?<Klammer>(\)))(?<Rest>(;[\s\S]*?))?/gm;

                  for (const match of outputCss.matchAll(regex)) {
                    if (match.groups.CSSClass === 'etsShop header .logo') {
                      this.globalConfig.misc.logoImage = 'url(' + match.groups.UrlLogoImageFile + ')';
                      continue;
                    }

                    if (match.groups.CSSClass === 'etsShop header') {
                      this.globalConfig.misc.headerImage = 'url(' + match.groups.UrlLogoImageFile + ')';
                      continue;
                    }
                  }
                }
              }
            }

            if (images) {
              imageCss = this.assignStandardImages(images.data, this.globalConfig.misc.headerContain);
            }
            if (imageCss.length > 0) {
              outputCss += imageCss;
            }

            if (designCss) {
              outputCss += designCss[0];
            }
            outputCss += ' .etsShop {display: block;} ';

            const styleEl = document.createElement('style');
            styleEl.id = 'style_hot_zone';
            this.globalConfig.misc.css = outputCss;

            if (designCss) {
              if (!designCss[0].match(/ets-shop .etsShop.header[ ]*?{.*?background-image:[ ]*none.*?}?/)) {

                // * Validate if Header is in contain mode
                if (designCss[0].match(/.etsShop header{.*background-size:contain/gm)) {
                  this.globalConfig.misc.headerContain = true;

                  // * For Headers using the "contains" picture mode => Different Header structure needed
                  // * Regex for searching HeaderImage URL and LogoImage URL
                  const regex = /.*?((?<CSSClass>(etsShop\sheader(\s.logo)?))[\s\S]*?)*(?<UrlLogoImageFile>((https?:)[\S]*?))(?<Klammer>(\)))(?<Rest>(;[\s\S]*?))?/gm;

                  for (const match of outputCss.matchAll(regex)) {
                    if (match.groups.CSSClass === 'etsShop header .logo') {
                      this.globalConfig.misc.logoImage = 'url(' + match.groups.UrlLogoImageFile + ')';
                      continue;
                    }

                    if (match.groups.CSSClass === 'etsShop header') {
                      this.globalConfig.misc.headerImage = 'url(' + match.groups.UrlLogoImageFile + ')';
                      continue;
                    }
                  }
                }
              }
            }


            if (outputCss.match('/.etsShop[ ]* nav .etsBtn.search{.*background-image:url()')) {
              outputCss += ' nav .etsBtn.search::before { display: none !important; }';
            }
            if (styleEl.sheet) {
              const sheet: any = styleEl.sheet;
              sheet.cssText = outputCss;
            } else {
              styleEl.appendChild(document.createTextNode(outputCss));
            }

            if (outputCss.match(/.etsShop[ ]*header[ ]*.logo[ ]*{.*?display:[ ]*none.*?}/gis)) {
              this.globalConfig.misc.logoHidden = 1;
            }

            if (document.getElementById('style_hot_zone')) {
              document.getElementById('style_hot_zone').remove();
            }
            document.body.appendChild(styleEl);
          } else {
            let imageCss = '';
            let outputCss = '';
            if (images) {
              imageCss = this.assignStandardImages(images.data, this.globalConfig.misc.headerContain);
            }
            if (imageCss.length > 0) {
              outputCss += imageCss;
            }
            outputCss = '.etsShop {display: block;}';

            const styleEl = document.createElement('style');
            styleEl.id = 'ets_style_active';

            if (styleEl.sheet) {
              const sheet: any = styleEl.sheet;
              sheet.cssText = outputCss;
            } else {
              styleEl.appendChild(document.createTextNode(outputCss));
            }
            document.body.appendChild(styleEl);
          }

          this.config.setShopIdentifier(this.partnerKey);

          if (this.cookiebanner == 'true') {
            this.initCookieBanner();
          }

          // guest logout and basket cleanup after leaving checkout as guest
          if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) === '1' && !this.basketService.isCheckout) {

            this.checkoutService.getPaymentSuccessTest.subscribe( val => {
                if (val) {
                  this.loginService.doLogout().subscribe( () => {
                    this.basketService.updateBasket().subscribe();
                  } );
                }
            });
          }
        });
      });
    }
  }

  /**
   * Sets partnershop related settings
   *
   * @param data any
   *
   * @returns void
   */
  private setPartnerShopSettings(data: any): void
  {
    if (data.data.show_packages_in_lists) {
      this.globalConfig.misc.showPackagesInList = data.data.show_packages_in_lists;
    }

    if (data.data.use_event_images) {
      this.globalConfig.misc.showEventImagesInLists = data.data.use_event_images;
    }

    if (data.data.show_prices_in_lists) {
      this.globalConfig.misc.showPricesInLists = data.data.show_prices_in_lists;
    }

    if (data.data.partner_new_shop_complete_active) {
      if ( data.data.partner_new_shop_complete_active >= 1) {
        this.config.setCompleteShop(true);
      } else {
        this.config.setCompleteShop(false);
      }
    }

    if (data.data.webshop_guest_orders) {
      this.globalConfig.misc.webshopGuestOrders = data.data.webshop_guest_orders;
    }

    if (data.data.show_search === 1) {
      this.globalConfig.misc.show_search = true;
    } else {
      this.globalConfig.misc.show_search = false;
    }

    if (data.data.cashier) {
      this.globalConfig.invoice.cashier = data.data.cashier;
    }

    if (data.data.webshop_best_place_flag) {
      this.globalConfig.misc.onlyBestPlace = data.data.webshop_best_place_flag;
    }

    if (data.data.webshop_language_code) {
      this.globalConfig.misc.languageCode = data.data.webshop_language_code;
      this.translate.use(data.data.webshop_language_code);
    }

    if (data.data.use_ets_agb) {
      this.globalConfig.footer.useEtsAgbs = data.data.use_ets_agb;
    }

    if (data.data.text_agb) {
      this.globalConfig.footer.textagb = data.data.text_agb;
    }

    if (data.data.text_agb_two) {
      this.globalConfig.footer.textagb2 = data.data.text_agb_two;
    }

    if (data.data.agb_link) {
      this.globalConfig.footer.linkAGB = data.data.agb_link;
    }

    if (data.data.use_ets_imprint) {
      this.globalConfig.footer.useEtsImprint = data.data.use_ets_imprint;
    }

    if (data.data.text_imprint) {
      this.globalConfig.footer.textImprint = data.data.text_imprint;
    }

    if (data.data.imprint_link) {
      this.globalConfig.footer.linkImprint = data.data.imprint_link;
    }

    if (data.data.partner_id) {
      this.globalConfig.misc.partner_id = data.data.partner_id;
    }

    if (data.data.use_ets_datenschutz) {
      this.globalConfig.footer.useEtsPrivacy = data.data.use_ets_datenschutz;
    }

    if (data.data.text_datenschutz) {
      this.globalConfig.footer.textPrivacy = data.data.text_datenschutz;
    }

    if (data.data.datenschutz_link) {
      this.globalConfig.footer.linkPrivacy = data.data.datenschutz_link;
    }

    if ( data.data.use_continue_shopping_url === 1)
    {
      this.globalConfig.detail.useContinueShoppingUrl = data.data.use_continue_shopping_url;
      if (data.data.continue_shopping_url) {
        this.globalConfig.detail.continueShoppingUrl = data.data.continue_shopping_url;
      }
    }

    if (data.data.webshop_timefilter_cancelation_text) {
      this.globalConfig.misc.webshopTimeFilterCancelText = data.data.webshop_timefilter_cancelation_text;
    }

    if (data.data.addinfo_required) {
      this.globalConfig.addressfield.addInfoReq = data.data.addinfo_required;
    }

    if (data.data.addinfo_description) {
      this.globalConfig.addressfield.addInfoDesc = data.data.addinfo_description;
    }

    if (!data.data.show_packages_in_events) {
      this.globalConfig.ticketing.showPackages = data.data.show_packages_in_events;
    }

    if (data.data.show_package_in_ticket_widget) {
      this.globalConfig.ticketing.showPackageInTicketWidget = data.data.show_package_in_ticket_widget;
    }

    if (data.data.package_ticket_text) {
      this.globalConfig.ticketing.BtnPackageTicketWidgetText = data.data.package_ticket_text;
    }

    if (!data.data.show_prices_in_event_info) {
      this.globalConfig.ticketing.showPriceInHeadliner = data.data.show_prices_in_event_info;
    }

    if (data.data.maintenance_mode_active) {
      this.globalConfig.misc.maintenance_mode_active = data.data.maintenance_mode_active;
    }

    if (data.data.maintenance_message) {
      this.globalConfig.misc.maintenance_message = data.data.maintenance_message;
    }

    if (data.data.webshop_group_bestseat) {
      this.globalConfig.ticketing.groupbestseat = (data.data.webshop_group_bestseat) ? 1 : 0;
    }

    if (data.data.allow_promotioncodes) {
      this.globalConfig.checkout.allowPromotioncodes = data.data.allow_promotioncodes;
    }
  }


  private setInfoTextSettings(data: any): void
  {
    if (data.data.use_header_infotext === 1) {
      this.globalConfig.infofield.showHeaderInfoText = data.data.use_header_infotext;
    }

    if (data.data.use_body_infotext === 1) {
      this.globalConfig.infofield.showBodyInfoText = data.data.use_body_infotext;
    }

    if (data.data.infotext_body) {
      this.globalConfig.infofield.infotextBody = data.data.infotext_body;
    }

    if (data.data.infotext_header) {
      this.globalConfig.infofield.infotextHeader = data.data.infotext_header;
    }

    if (data.data.use_body_infotext_index_only) {
      this.globalConfig.infofield.showBodyTextIndexOnly = data.data.use_body_infotext_index_only;
    }
  }

  /**
   * Sets directbooking related settings
   *
   * @param data
   *
   * @returns void
   */
  private setDirectBookingSettings(data: any): void
  {
    if (data.data.direct_show_date) {
      this.globalConfig.directbooking.showDate = data.data.direct_show_date;
    }

    if (data.data.direct_show_datetime) {
      this.globalConfig.directbooking.showDateTime = data.data.direct_show_datetime;
    }

    if (data.data.direct_show_description) {
      this.globalConfig.directbooking.showDescription = data.data.direct_show_description;
    }
  }


  /**
   *  Set calendar component related settings
   *
   * @param data
   *
   * @returns void
   */
  private setCalendarSettings(data: any): void
  {
    if (data.data.webshop_calendar) {
      this.globalConfig.misc.showCalendar = data.data.webshop_calendar;
    }

    if (data.data.use_custom_calendar) {
      this.globalConfig.calendar.customDescription = data.data.use_custom_calendar;
    }

    if (data.data.webshop_calendar_directbooking) {
      this.globalConfig.calendar.directBooking = data.data.webshop_calendar_directbooking;
    }

    if (data.data.calendar_info_text) {
      this.globalConfig.calendar.infoText = data.data.calendar_info_text;
    }

    if (data.data.calender_more_info_text) {
      this.globalConfig.calendar.moreInfoText = data.data.calender_more_info_text;
    }

    if (data.data.calendar_headline) {
      this.globalConfig.calendar.title = data.data.calendar_headline;
    }

    if (data.data.calendar_image) {
      this.globalConfig.calendar.imageUrl = data.data.calendar_image;
    }

    if (data.data.calendar_videourl) {
      this.globalConfig.calendar.videoUrl = data.data.calendar_videourl;
    }

    if (data.data.webshop_calendar_start_month) {
      this.globalConfig.calendar.calendarStartMonth = data.data.webshop_calendar_start_month;
    }

    if (data.data.calendar_highlight_events) {
      this.globalConfig.calendar.highlightEvents = data.data.calendar_highlight_events;
    }
    if (data.data.calendar_highlights_caption) {
      this.globalConfig.calendar.highlightCaption = data.data.calendar_highlights_caption;
    }
  }

  assignStandardImages(images: any, headerContain: boolean = false): string
  {
    let imageCss = '';
    const standardImageMapping: object = {
      'benutzer-account-symbol': '.etsShop nav .etsButtons .etsButton.user',
      'benutzer-account-symbolmouseover': '.etsShop nav .etsButtons .etsButton.user:hover',
      'warenkorb-symbol': '.etsShop nav .etsButtons .etsButton.cart',
      'warenkorb-symbolmouseover': '.etsShop nav .etsButtons .etsButton.cart:hover',
      'ticket-button': '.etsShop .eventList .tickets',
      'ticket-buttonmouseover': '.etsShop .eventList .tickets:hover',
      ladeanzeige: '.etsShop .moreLoader',
      lupe: '.etsShop nav .etsBtn.search',

    };
    for (const image of images) {

      if (standardImageMapping.hasOwnProperty(image.standard_image)) {
        if (image.standard_image === 'header') {
          if (!headerContain) {
            imageCss += standardImageMapping[image.standard_image] + ' {background-image: url(' + this.mediaUrl + this.partnerKey + '/' + image.image_file + ');} \n';

          }
        }

        if (image.standard_image === 'ticket-button') {
          imageCss += standardImageMapping[image.standard_image] + ' {background-image: url(' + this.mediaUrl + this.partnerKey + '/' + image.image_file + ') !important; background-size: 100% 100%;} \n';
          imageCss += '@media screen and (max-width: 767px) {';
          imageCss += '.eventList .tickets:before, .eventList .tickets:after {background-image: url(' + this.mediaUrl + this.partnerKey + '/' + image.image_file + ') !important;} \n';
          imageCss += '.etsText:after { content: "" !important;}';
          imageCss += '.etsText:before { content: "" !important;}';
          imageCss += '.tickets:after { content: "" !important;}';
          imageCss += '}';
          imageCss += '.etsfont-ticket-button:before { content: "" !important; }';
        }
        if ( image.standard_image === 'benutzer-account-symbol') {
          imageCss += '.etsfont-user-red:before { content: "" !important;}';
        }

        if ( image.standard_image === 'ticket-button') {
          imageCss += '.etsfont-ticket-button:before { content: "" !important;}';
        }
        if ( image.standard_image === 'ticket-buttonmouseover') {
          imageCss += '.etsfont-ticket-button:hover:before { content: "" !important;}';
          imageCss += '.eventList .holder.hover:hover .actions .etsfont-ticket-button { background-image: url(' + this.mediaUrl + this.partnerKey + '/' + image.image_file + ') !important;}';
          imageCss += '.eventList .holder.hover:hover .actions .etsfont-ticket-button:before { content: "" !important; }';
        }

        if ( image.standard_image === 'lupe') {
          imageCss += '.etsShop nav .etsBtn.search:before { content: "" !important;}';
        }

        if ( image.standard_image === 'benutzer-account-symbolmouseover') {
          imageCss += '.etsShop nav .etsButtons .etsButton.user:hover { background-image: url(' + this.mediaUrl + this.partnerKey + '/' + image.image_file + ') !important; }';
        }
        if ( image.standard_image === 'warenkorb-symbol') {
          imageCss += '.etsfont-cart-red:before { content: "" !important;}';
        }

        if ( image.standard_image === 'warenkorb-symbolmouseover') {
          imageCss += '.etsShop nav .etsButtons .etsButton.cart:hover { background-image: url(' + this.mediaUrl + this.partnerKey + '/' + image.image_file + ') !important; }';
        }
      }
    }
    return imageCss;
  }
}
