import { BaseComponent } from '../base-component/base-component';
import {Component, Input, OnInit} from '@angular/core';
import {of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {EtsSearchService} from '../ets-search-service/ets-search-service';
import {EtsSearchData} from '../ets-search-service/ets-search-data';
import { EtsComponentRouterService } from '../ets-component-router-service/ets-component-router.service';

@Component({
  selector: 'app-ets-search',
  templateUrl: './ets-search.component.html',
  styleUrls: ['./ets-search.component.css']
})
export class EtsSearchComponent extends BaseComponent implements OnInit {
  @Input() identifier: string;

  searchVisible: boolean;
  searchInput$ = new Subject<string>();
  searchResults = {
    events: [],
    artists: [],
    vouchers: [],
    lottery: [],
    location: []
  };

  constructor(
    public searchService: EtsSearchService,
    private routerService: EtsComponentRouterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.searchVisible = false;
    this.cleanSearchResults();

    this.searchInput$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.searchService.getSearchData(term).pipe(
        catchError(err => {
          console.log(err);
          return of([]);
        }), // empty list on error
      )),
    ).subscribe((data: EtsSearchData[]) => {
      this.cleanSearchResults();
      for (let i = 0; i < ((data.length > 3) ? 3 : data.length); i++) {
        this.searchResults.events.push({
          dateId: data[i].id,
          image: data[i].imageUrl,
          name: data[i].title,
          date: data[i].date,
          hideEventTime: data[i].hideEventTime,
        });
      }
      if (this.searchResults.events.length > 0 || this.searchResults.artists.length > 0 || this.searchResults.vouchers.length > 0 || this.searchResults.location.length > 0 || this.searchResults.lottery.length > 0) {
        this.searchVisible = true;
      } else {
        this.searchVisible = false;
      }
    });
  }

  onFieldChange($event): void {
    this.searchInput$.next($event.target.value);
  }

  onBlur(): void {
    setTimeout(() => {
      this.searchVisible = false;
    }, 200);
  }

  onFocus(): void {
    if (this.searchResults.events.length > 0 || this.searchResults.artists.length > 0 || this.searchResults.vouchers.length > 0 || this.searchResults.location.length > 0 || this.searchResults.lottery.length > 0) {
      this.searchVisible = true;
    }
  }

  navigateToSearch(): void
  {
    this.searchVisible =  false;
    this.routerService.navigateEmbed([{name: this.embedName, target: 'searchresult'}]);
  }

  cleanSearchResults(): void {
    this.searchResults = {
      events: [],
      artists: [],
      vouchers: [],
      lottery: [],
      location: []
    };
  }
}
