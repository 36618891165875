import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import { EtsCouponData } from './ets-coupon-data';

@Injectable({
  providedIn: 'root'
})
export class EtsCouponService {

  etsCouponSettings: EtsCouponData;
  constructor(
    private config: EtsConfigService,
    @Inject('ETS_API_URL') public apiUrl,
    private http: HttpClient,
  ) {
    this.etsCouponSettings = new EtsCouponData();
   }

  /**
   * Returns the coupon code settings
   * @returns Observable<EtsCouponData>
   */
  getCouponCodeSettings(partnerhash: string = ''): Observable<EtsCouponData> {
    let headers = new HttpHeaders();
    if (partnerhash) {
      headers = new HttpHeaders({partnershopId: partnerhash});
    } else {
      headers = new HttpHeaders({partnershopId: this.config.getShopIdentifier()});
    }
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get<EtsCouponData>(this.apiUrl + 'couponcode', {headers}).pipe(
      map(data => {
        this.etsCouponSettings = data['coupons'];
        return data;
      })
    );
  }

  /**
   * Validates couponcode
   * @param couponCode string
   * @returns Observable<boolean>
   */
  validateCouponCode(couponCode: string): Observable<boolean> {
    let headers = this.getHttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const dataForm: any = new URLSearchParams();
    dataForm.set('couponCode', couponCode);

    return this.http.post(this.apiUrl + 'couponcode/check', dataForm, {headers}).pipe(
      map((data: any) => {
        if (data && data.status && data.status === 'success') {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  /**
   * Sets the coupon code into the Backend Session
   * @param couponCode: string
   * @returns boolean
   */
  setCouponCode( couponCode: string, loginToken: string): Observable<boolean> {

    if (loginToken !== '') {
      let headers = this.getHttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + loginToken);
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
      const dataForm: any = new URLSearchParams();
      dataForm.set('couponCode', couponCode);

      return this.http.post(this.apiUrl + 'couponcode', dataForm , {headers}).pipe(
        map((data: any) => {
          if (data && data.status && data.status === 'success') {
            return true;
          } else {
            return false;
          }
        },
        error => {
          console.log(error);
        })
      );
    } else {
      return new BehaviorSubject(false).asObservable();
    }
  }

  /**
   * Returns essential header properties for the http request
   * @returns HttpHeaders
   */
  private getHttpHeaders(): HttpHeaders {
    const httpHeaders = new HttpHeaders({partnershopId: this.config.getShopIdentifier()});
    return httpHeaders;
  }
}
