import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {EtsListingService} from '../ets-listing-service/ets-listing.service';
import {EtsListingData} from '../ets-listing-service/ets-listing-data';
import {BaseComponent} from '../base-component/base-component';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import { LogService } from '../services/LogService.service';

@Component({
  selector: 'app-ets-listing',
  templateUrl: './ets-listing.component.html',
  styleUrls: ['./ets-listing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EtsListingComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('moreLoaderId', { static: false }) moreLoader: ElementRef;

  @Input() identifier: string;
  listing: EtsListingData;
  loaded: boolean;
  loadingInProgress: boolean;
  loadFirst: boolean;
  obs: IntersectionObserver;
  preview: boolean;
  globalConfig: any;

  constructor(private listingService: EtsListingService, private config: EtsConfigService, public logService: LogService) {
    super();
  }

  ngOnInit(): void {
    this.loadFirst = true;
    this.loaded = false;
    this.loadingInProgress = true;
    this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
      this.globalConfig = this.config.getConfigObject();
      if (identifier !== '') {
        if (this.config.getPreview() === false) {
          this.listingService.loadListingData(this.identifier).subscribe(listing => {
            this.listing = listing;
            this.loaded = true;
            this.loadingInProgress = false;
          }, err => {
            this.loadingInProgress = false;
            this.loaded = false;
          });
        }
        else {
          this.listingService.loadListingData(this.identifier, 10).subscribe(listing => {
            this.listing = listing;
            this.loaded = true;
            this.loadingInProgress = false;
          });
        }
      }
    });

    this.obs = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && this.loadingInProgress === false && this.listingService.checkForMorePages(this.identifier) && this.config.getPreview() === false) {
          this.loadingInProgress = true;
          this.listingService.loadListingData(this.identifier).subscribe();
        }
      });
    }, {
      rootMargin: '0px 0px 50px 0px',
      threshold: 0
    });
  }

  ngAfterViewInit(): void {
    this.obs.observe(this.moreLoader.nativeElement);
  }
}
