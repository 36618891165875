import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-ets-accordion-child',
  templateUrl: './ets-accordion-child.component.html',
  styleUrls: ['./ets-accordion-child.component.css']
})
export class EtsAccordionChildComponent implements OnInit {
  @ViewChild('accordionContent') accordionContent: ElementRef;
  openState: boolean;
  scrollHeight: number = 0;
  @Input() state: string;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {

    if (this.state && this.state === 'open') {
      this.openState = true;
    } else {
      this.openState = false;
    }

    // Need to be a timeout bc of racecondition
    setTimeout(() => {
      this.scrollHeight = this.accordionContent?.nativeElement?.scrollHeight;
    }, 0);
  }

  onClick($event: any): void {
    this.openState = !this.openState;
    const panel = $event.target.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = 'fit-content';
    }
  }
}
