<ng-container *ngIf="globalConfig.infofield.showBodyInfoText && globalConfig.infofield.showBodyTextIndexOnly && globalConfig.infofield.infotextBody !== ''">
  <div class="bodyInfoText" [innerHTML]="globalConfig.infofield.infotextBody | allowHTML"></div>
</ng-container>
<div *ngIf="globalConfig.misc.showCalendar">
  <ng-container *ngIf="!initialLoad">
    <div *ngIf="loaded" class="headLiner clearfix">
      <div class="image basicSlider">
        <swiper [pagination]="{dynamicBullets:true}" *ngIf="!globalConfig.calendar.customDescription">
          <ng-container>
            <ng-template  *ngFor="let photo of details?.headliner?.photoUrls" swiperSlide>
              <img [src]="photo.url">
              <div class="infoOverlay">
                <div class="title">{{photo.title}}</div>
                <div class="copyright" *ngIf="photo.copyright !== ''">{{'ets-detail-headliner.Tickets' | translate}}: {{photo.copyright}}</div>
              </div>
            </ng-template>
          </ng-container>
        </swiper>
        <ng-container *ngIf="globalConfig.calendar.customDescription">
          <img *ngIf="this.globalConfig.calendar.imageUrl !== ''" style="width: 100%;" [src]="photoUrl">
        </ng-container>
      </div>
      <div class="info">
        <div *ngIf="!globalConfig.calendar.customDescription" class="etsTitle">{{title}}</div>
        <div *ngIf="globalConfig.calendar.customDescription" class="etsTitle" [innerHTML]="title | allowHTML"></div>

        <button class="etsBtn " (click)="scrollToCalendar()"><span>{{'calendar.jumpToCalendar' | translate}}</span></button>
      </div>
      <div *ngIf="externalContent" class="headliner video">
        <iframe [hidden]="videoLink?.length <= 0" [src]="videoLink | safe" width="560" height="315" title="Youtube video player" [frameBorder]="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <div class="shortDescription" [innerHTML]="eventDescription"></div>
    <app-ets-accordion *ngIf="globalConfig.calendar.customDescription">
      <app-ets-accordion-child *ngIf="globalConfig.calendar.customDescription && globalConfig.calendar.moreInfoText !== ''" title="{{'ets-detail.showMore' | translate}}">
        <div [innerHTML]="globalConfig.calendar.moreInfoText | allowHTML"></div>
      </app-ets-accordion-child>
    </app-ets-accordion>

    <app-ets-accordion *ngIf="!globalConfig.calendar.customDescription && ((details?.infoBox && details?.infoBox?.moreInfoHTML !== ''))">
      <app-ets-accordion-child *ngIf="details?.infoBox.moreInfoHTML" title="{{'ets-detail.showMore' | translate}}">
        <div [innerHTML]="details.infoBox.moreInfoHTML"></div>
      </app-ets-accordion-child>
      <app-ets-accordion-child *ngIf="details?.infoBox.artistHTML" title="{{'ets-detail.artist' | translate}}">
        <div [innerHTML]="details.infoBox.artistHTML"></div>
      </app-ets-accordion-child>
    </app-ets-accordion>
  </ng-container>
  <!-- * BEG: Highlight Events -->
  <div *ngIf="configService.getConfigObject().calendar.highlightEvents !== ''" class="eventList noBorder">
    <div *ngIf="loaded" class="flexTable listTable">
      <div class="etsContent clearfix sameSizeParent">
        <h2 class="calendarHighlightCaption" *ngIf="configService.getConfigObject().calendar.highlightCaption !== ''" >{{configService.getConfigObject().calendar.highlightCaption}}</h2>
        <div *ngFor="let event of highlightEvents?.events;" class="line">
          <div class="holder hover hover-link" [appEtsRouterLink]="[{name: embedName, target: 'detail-' + event.dateId}]">
            <div class="eventPicture">
              @if (event.image !== '') {
                <div *ngIf="configService.getConfigObject().misc.showEventImagesInLists == 1" class="highlightlistingimage" [ngStyle]="{'background-image': 'url(' + event.image + ')', 'background-size': 'contain'}"></div>
              } @else {
                <div *ngIf="configService.getConfigObject().misc.showEventImagesInLists == 1" class="highlightlistingimage" [ngStyle]="{'background-image': 'url(https://easyticket.de/img/0.jpg)', 'background-size': 'contain'}"></div>

              }

            </div>
            <div class="eventName" [ngStyle]="configService.getConfigObject().misc.showEventImagesInLists == 0 && {'padding-top': 0}" data-name="eventName">
              <div class="etsTitle">{{event.name}}</div>
              <div class="subTitle" [innerHTML]="event.description"></div>
            </div>
            <div class="dateAndLocation" [ngStyle]="configService.getConfigObject().misc.showEventImagesInLists == 0 && {'padding-top': 0}" data-name="dateAndLocation">
              <div *ngIf="!event?.hideDateFlag && !event.showEventTime" class="date">{{event.date.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy, H:mm':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}} {{'ets-listing.Time' | translate}}</div>
              <div *ngIf="!event?.hideDateFlag && event.showEventTime" class="date">{{event.date.replace(' ', 'T') | date:'EEEE, dd.MM.yyyy':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}}</div>

              <div class="location">{{event.location}}</div>
              <div *ngIf="configService.getConfigObject().misc.showPricesInLists == 1 && event.prices" class="prices">{{ event.prices }}</div>
              <div class="actions clearfix">
                <div class="ets-ticket-wrapper ets-ticket-wrapper-listing">
                  <svg class="ets-ticket-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1799.36 557.58"><path class="ets-ticket-path" d="m1728.05,454.87c0-39.4,31.92-71.35,71.31-71.39v-32.91c-39.43,0-71.39-31.96-71.39-71.39s31.96-71.39,71.39-71.39v-33.69c-39.37-.07-71.26-32-71.26-71.39s31.89-71.32,71.26-71.39V0H0v31.32s.04,0,.07,0c39.43,0,71.39,31.96,71.39,71.39S39.49,174.11.07,174.11c-.02,0-.04,0-.07,0v33.69c39.4.04,71.33,31.98,71.33,71.39S39.4,350.54,0,350.58v32.91s0,0,.01,0c39.43,0,71.39,31.96,71.39,71.39S39.44,526.26.01,526.26c0,0,0,0-.01,0v31.32h1799.36v-31.32c-39.39-.04-71.31-31.99-71.31-71.39Z" fill="#f2001c"/></svg>
                  <ng-container *ngIf="event.organizerId === '539'">
                    <div *ngIf="!event.notBookable">{{'ets-listing.Tickets'| translate}}</div>
                    <div *ngIf="event.notBookable" class="soldOut">{{'ets-listing.tickets_soldout'| translate}}</div>
                  </ng-container>
                  <ng-container *ngIf="event.organizerId !== '539'">
                    <div>{{'ets-listing.Tickets'| translate}}</div>
                  </ng-container>
                </div>
                <div class="clearfix"></div>
                <div *ngIf="event.printHome" class="printAtHome" title="Mit print@home können Sie Ihre Tickets bis kurz vor Veranstaltungsbeginn auf unserer Website buchen und selbst ausdrucken. Es ist daher eine Alternative zum traditionellen Papierticket. Bitte beachten Sie, dass kein gemeinsamer Warenkorb aus print@home- und Papiertickets möglich ist. Hier müssen Sie eine gesonderte Bestellung aufgeben.">
                  {{'ets-listing.printAtHome' | translate }}
                </div>
                <ng-container *ngIf="configService.getConfigObject().misc.showPackagesInList == 1 && event.packages.length > 0">
                  <ng-container *ngFor="let package of event.packages">
                    <a *ngIf="package['price_text'] && package['price_text'].length > 0" class="link" [appEtsRouterLink]="[{name: embedName != 'subComponent' ? embedName : 'Page', target: 'detail-777' + package.package_id}]">{{package['price_text']}}</a>
                    <a *ngIf="!package['price_text'] || package['price_text'].length <= 0" class="link" [appEtsRouterLink]="[{name: embedName != 'subComponent' ? embedName : 'Page', target: 'detail-777' + package.package_id}]">Dauerkarte erhältlich</a>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- * END: Highlight Events -->
  <div class="flexBox">
    <ngx-spinner name="calendarSpinner" [fullScreen]="true" type="ball-clip-rotate" size="medium">
      <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
    </ngx-spinner>
    <div>
      <mat-calendar [headerComponent]="calendarHeader"  class="calendar" [dateFilter]="dateFilter" [dateClass]="dateClass" [selected]="selectDate" (selectedChange)="selectDate($event)"></mat-calendar>
      <div class="indicatorWrapper">
        <div class="line"><span class="calendar indicatorLight statusAvailable"></span>{{ 'calendar.ticketAvailable' | translate }}</div>
        <div class="line"><span class="calendar indicatorLight statusnotAvailable"></span>{{ 'calendar.notAvailable' | translate }}</div>
        <div  style="display: none;" class="line"><span class="calendar indicatorLight statusNoEvent"></span>{{ 'calendar.noEvent' | translate }}</div>
      </div>
    </div>
    <div *ngIf="mutipleEvents">
      <div class="eventList">
        <div class="">
          <h4 class="headline date">{{this.calendar.activeDate | date:'EEEE, dd.MM.yyyy':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}}</h4>
          <h5 *ngIf="selectedEvents.length > 0">{{ 'calendar.moreTicketsAvailable' | translate: {date: selectedDate} }}</h5>
          <h5 *ngIf="selectedEvents.length === 0">{{ 'calendar.notBookable' | translate }}</h5>
        </div>

        <ng-container *ngFor="let event of selectedEvents">
          <div  class="listItem" [appEtsRouterLink]="[{name: embedName, target: ((globalConfig.calendar.directBooking) ? 'calticket-' : 'detail-') + event.dateId}]">
            <div class="eventListItem">
              <div>
                {{event.name}}
              </div>
              <div *ngIf="!event.showEventTime">
                {{event.date | date: 'H:mm'}} Uhr - {{event.location}}
              </div>
              <div *ngIf="event.showEventTime">
                {{event.location}}
              </div>
            </div>
            <div>
              <button class="etsBtn calendar">Tickets</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
