<div #etsWidgetButtonsId class="etsButtons" id="menubuttons">
  <div class="buttonHolder clearfix">
    <div class="etsLeft clearfix">
    </div>
    <div class="etsRight clearfix">
      <div *ngIf="showLogin" #etsUserFlyout [ngClass]="{'etsButton': true,'etsfont-user-red': true, 'showBasket': showUserFlyout(),  'user': true, 'active': login.isLoggedInValue}" (click)="routeClick($event)">
        <span *ngIf="!isGuest"></span>
        <div (mouseover)="showUserFlyoutEnter($event)" (mouseout)="showUserFlyoutLeave($event)" *ngIf="!boschShop && !login.isLoggedInValue" [ngClass]="{'showBasket': showUserFlyout(), 'flyout': true, 'box': true, 'flyout-left': calcIsLeft(), 'flyout-right': !calcIsLeft(), 'showFlyout': showUserFlyout()}">
          <div style="display: flex;">
            <h3 >{{'ets-menu-buttons.login.headline' |translate}}</h3>
            <div class="profileCloseBtn">
              <a (click)="toggleUserFlyout()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 25px; height: 25px;">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </a>
            </div>
          </div>
          <form method="post">
            <input [(ngModel)]="loginUser" type="email" name="user" autocomplete="email" value="" placeholder="{{'ets-menu-buttons.login.email' | translate}}" (keyup.enter)="doLogin()"/>
            <input [(ngModel)]="loginPassword" type="password" autocomplete="current-password" name="pass" placeholder="{{'ets-menu-buttons.login.password' | translate}}" (keyup.enter)="doLogin()"/>
            <a [ngClass]="{'etsBtn': true, 'submitButton': true, 'login': true, 'inactive': this.loginInProgress}" (click)="doLogin()">{{'ets-menu-buttons.login.loginButton' | translate}}</a>
            <a [appEtsRouterLink]="[{name: identifier, target: 'checkout-password'}]" class="forgotPassword link">{{'ets-menu-buttons.login.forgotPasswordButton' | translate}}</a>
          </form>
          <div class="newAccount">
            <h3>{{'ets-menu-buttons.register.headline' | translate}}</h3>
            <a [appEtsRouterLink]="[{name: identifier, target: 'checkout-register'}]" class="etsBtn" (click)="toggleUserFlyout()">{{'ets-menu-buttons.register.registerButton' | translate}}</a>
          </div>
        </div>
        <div *ngIf="login.isLoggedInValue || isGuest" [ngClass]="{'flyout': true, 'box': true, 'flyout-left': calcIsLeft(), 'flyout-right': !calcIsLeft()}">
          <h3 *ngIf="login.loginDataValue.forename && login.loginDataValue.forename.length > 0">{{'ets-menu-buttons.logedIn.headline' | translate}}, {{login.loginDataValue.forename}} {{login.loginDataValue.surname}}!</h3>
          @if (isGuest) {
            <a class="etsBtn" (click)="doLogout()">{{'ets-menu-buttons.logedIn.guestLogout' | translate}}</a>
          } @else {
            <a class="etsBtn" (click)="doLogout()">{{'ets-menu-buttons.logedIn.logoutButton' | translate}}</a>
          }
        </div>
      </div>
      <div *ngIf="showBasket" #etsBasketFlyout class="etsButton cart etsfont-cart-red" [class.active]="(basket.basketSubjectValue.items && basket.basketSubjectValue.items.length > 0)">
        <span class="basketEventCounter" *ngIf="basket.basketSubjectValue.items && basket.basketSubjectValue.items.length > 0">{{basket.basketSubjectValue.items.length}}</span> <a class="link" [appEtsRouterLink]="[{name: identifier, target: 'basket-detail', base: redirectbasket}]"></a>
        <div [ngClass]="{'cartFlyout': true, 'flyout': true, 'box': true, 'flyout-left': calcIsLeft(), 'flyout-right': !calcIsLeft()}">
          <h3>{{'basket.your_basket' | translate}}</h3>
          <div class="flexTable listTable cartTable">
            <div *ngIf="basket.basketSubjectValue.items && basket.basketSubjectValue.items.length === 0" class="etsContent cartEmpty">
              <div class="line">
                <div class="notice">
                  {{'basket.empty' | translate }}
                </div>
              </div>
              <a class="etsBtn" style="margin-top: 1rem;" (click)="(globalConfig.detail.useContinueShoppingUrl === 1 && globalConfig.detail.continueShoppingUrl.length > 0) ? redirectToUrl(globalConfig.detail.continueShoppingUrl) : router.resetToHome()">{{'basket.landingPage' | translate}}</a>
            </div>
            <div *ngIf="basket.basketSubjectValue.items && basket.basketSubjectValue.items.length > 0" class="etsContent cart">
              <ngx-spinner [showSpinner]="basket.basketLoadingValue" [fullScreen]="false" type="ball-clip-rotate" size="medium">
                <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
              </ngx-spinner>
              <div class="etsContent clearfix">
                <div class="line" [style]="!item.showActions ? 'border-top: unset;' : ''" *ngFor="let item of basket.basketSubjectValue.items">
                  <div class="itemData">
                    <div class="eventPicture">
                      <div class="image" [ngStyle]="{'background-image': 'url(' + item.imageUrl + ')'}"></div>
                      <div class="actions">
                        <a *ngIf="!config.getConfigObject().detail.keepEventUrl || basket.urlToEventId(item.eventId) === null" title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" [appEtsRouterLink]="[{name: detailIdentifier, target: 'detail-' + item.eventId}]" class="edit actionButton handled"><div>D</div></a>
                        <a *ngIf="config.getConfigObject().detail.keepEventUrl && basket.urlToEventId(item.eventId) !== null" title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasketWithRedirect(item.deleteParam, basket.urlToEventId(item.eventId))" class="edit actionButton handled"><div>D</div></a>
                        <a title="{{ 'basket.delete' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" class="trash actionButton handled"></a>
                      </div>
                    </div>
                    <div class="eventName">
                      <a [appEtsRouterLink]="[{name: detailIdentifier, target: 'detail-' + item.eventId}]" class="etsTitle">{{item.title}}</a>
                      <div class="subTitle">{{item.subTitle}}</div>
                    </div>
                    <div class="dateAndLocation">
                      <div *ngIf="!item.showEventTime && !item.hideDateFlag" class="date">{{item.date | date: 'EE, dd.MM.yyyy, H:mm':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}} {{config.getConfigObject().misc.languageCode === 'de' ? 'Uhr' : ''}}</div>
                      <div *ngIf="item.showEventTime && !item.hideDateFlag" class="date">{{item.date.split(' ')[0] | date: 'EE, dd.MM.yyyy':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}}{{item.date.split(',')[1]}}</div>
                      <div class="location">{{item.location}}</div>
                    </div>
                    <div class="ticketInfo">
                      <div class="quantity">{{'basket.amount' | translate}}: {{item.quantity}}</div>
                      <div class="details">{{item.seatInfo}}</div>
                      <div class="priceGroups">{{item.priceGroups}}</div>
                    </div>
                  </div>
                  <div class="actions">
                    @if (item.showActions) {
                      <a *ngIf="!config.getConfigObject().detail.keepEventUrl || basket.urlToEventId(item.eventId) === null" title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" [appEtsRouterLink]="[{name: detailIdentifier, target: 'detail-' + item.eventId}]" class="edit actionButton handled"><div>D</div></a>
                      <a *ngIf="config.getConfigObject().detail.keepEventUrl && basket.urlToEventId(item.eventId) !== null" title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasketWithRedirect(item.deleteParam, basket.urlToEventId(item.eventId))" class="edit actionButton handled"><div>D</div></a>
                      <a title="{{ 'basket.delete' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" class="trash actionButton handled"><div>A</div></a>
                    }
                  </div>
                  <div class="price itemPacked">{{ item.price | currency }} €</div>
                </div>
                <div class="line timeoutError cartNotEmpty" [class.off]="(basket.timerStringValue !== '00:00')">
                  {{'basket.reservationTimeout' | translate}}
                </div>
                <div class="line cartNotEmpty">
                  <div class="amountCaption">
                    <strong>{{'basket.totalPrice' | translate}}</strong> <span class="shippingInfo"><span *ngIf="basket.chosenShipping <= 0 || basket.chosenShipping == null || basket.chosenShipping === undefined" >{{'general.plus' | translate}} </span><span *ngIf="basket.chosenShipping > 0">{{'general.including' | translate}} </span> <app-ets-overlay title="Unsere Versandgebühren" linkTitle="{{'basket.shippingFee' | translate}}">
                    <div class="scroller">
                        <p class="description">{{'basket.shippingInformations' | translate}}<br>
                          {{'basket.shippingInformations2' | translate}}</p>
                      <ul class="optionsList">
                        <li class="clearfix" *ngFor="let shipping of basket.basketSubjectValue.shipping">
                          <div class="info">
                            <h3>{{shipping.title}}</h3>
                          </div>
                          <div class="price">{{shipping.price}}</div>
                        </li>
                      </ul>
                    </div>
                    </app-ets-overlay></span>
                    <span title="Verbleibende Reservierungszeit" class="countdown flyoutCountdown">

                        <span class="label"> {{'basket.remainingReservationTime' | translate}}:</span> <span class="time">{{basket.timerStringValue}}</span>
                    </span>
                  </div>
                  <div class="price">
                    <div class="totalPrice">{{ basket.basketSubjectValue.totalPrice | currency }} €</div>
                    <div class="shippingInfo"><span *ngIf="basket.chosenShipping <= 0 || basket.chosenShipping === undefined || basket.chosenShipping === null">{{'general.plus' | translate}} </span><span *ngIf="basket.chosenShipping > 0">{{ 'general.including' | translate }}</span> <app-ets-overlay title="Unsere Versandgebühren" linkTitle="Versandgebühren">
                      <div class="scroller">
                      <p class="description">{{'basket.shippingInformations'}}<br>
                        {{'basket.shippingInformations2' | translate}}</p>
                      <ul class="optionsList">
                        <li class="clearfix" *ngFor="let shipping of basket.basketSubjectValue.shipping">
                          <div class="info">
                            <h3>{{shipping.title}}</h3>
                          </div>
                          <div class="price">{{shipping.price}}</div>
                        </li>
                      </ul>
                      </div>
                    </app-ets-overlay>.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cartButtons">
            <div class="cartNotEmpty" [class.off]="!(basket.basketSubjectValue.items && basket.basketSubjectValue.items.length > 0)">
              @if (boschShop) {
                <!-- ! Special Case Boschshops -->
                <a [appEtsRouterLink]="[{name: identifier, target: 'basket-detail', base: redirectbasket}]" class="etsBtn">{{'basket.shoppingcart' | translate}}</a>&nbsp;<a class="etsBtn" (click)="doGuestLogin(); setCheckout();">{{'basket.checkout' | translate}}</a>
              } @else {
                @if (couponRequired) {
                  <a *ngIf="couponRequired" [appEtsRouterLink]="[{name: identifier, target: 'checkout-promotionCode'}]" class="etsBtn" (click)="setCheckout()">{{'basket.checkout' | translate}}</a>
                } @else {
                  <a [appEtsRouterLink]="[{name: identifier, target: 'basket-detail', base: redirectbasket}]" class="etsBtn">{{'basket.shoppingcart' | translate}}</a>&nbsp;<a *ngIf="!couponRequired" [appEtsRouterLink]="[{name: identifier, target: 'checkout-login'}]" class="etsBtn" (click)="setCheckout()">{{'basket.checkout' | translate}}</a>
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
