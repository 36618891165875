import { Injectable } from '@angular/core';
import { EtsConfigService } from '../ets-config-service/ets-config.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {

  constructor(public configService: EtsConfigService) {}

  public getHttpHeaders(): HttpHeaders
  {

    let httpHeaders = new HttpHeaders();

    if (this.configService.serverCookie !== '') {
      httpHeaders = httpHeaders.append('X-Server', this.configService.serverCookie);
    }

    if (this.configService.getShopIdentifier() !== '') {
      httpHeaders = httpHeaders.append('partnershopId', this.configService.getShopIdentifier());
    }

    return httpHeaders;
  }

}
