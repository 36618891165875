<li [ngClass]="{'ets-accordion-element': true, 'etsOpened': openState}">
  <div (click)="onClick($event)" style="display: flex; justify-content: space-between; margin-right: 20px; cursor: pointer;">
    <div [ngClass]="{'etsTitle': true, 'etsOpened': openState}">{{title}}</div>
    <svg class="chevron" [style]="openState ? 'display: none;'  : 'display: block;' " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
    <svg class="chevron" [style]="openState ? 'display: block;' : 'display: none;'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
    </svg>
  </div>
  <div #accordionContent class="etsContent" [style]="{'maxHeight': (openState) ? scrollHeight + 'px' : null}">
    <ng-content></ng-content>
  </div>
</li>
