import {Component, Input} from '@angular/core';
import {BaseRouterComponent} from '../base-component/base-router-component';

@Component({
  selector: 'app-ets-calendar-outer',
  templateUrl: './ets-calendar-outer.component.html',
})
export class EtsCalendarOuterComponent extends BaseRouterComponent{
  @Input() identifier: string;
}
