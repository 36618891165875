import {Component, HostListener, OnInit} from '@angular/core';
import {EtsAGB, EtsDatenschutz, EtsImpressum, EtsUeberUns} from '../ets-checkout-service/ets-checkout-data';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import {take} from 'rxjs/operators';
import {EtsCheckoutService} from '../ets-checkout-service/ets-checkout-service';

@Component({
  selector: 'app-ets-footer',
  templateUrl: './ets-footer.component.html',
  styleUrls: ['./ets-footer.component.css']
})
export class EtsFooterComponent implements OnInit {
  sticky: boolean;
  agb: EtsAGB;
  impressum: EtsImpressum;
  datenschutz: EtsDatenschutz;
  ueberUns: EtsUeberUns;
  logoHidden: boolean;
  globalConfig: any;
  showTopScroller: boolean;
  copyRightDate: string;
  overlayAGBHidden: boolean;
  overlayPrivacyHidden: boolean;
  overlayImprintHidden: boolean;
  linkAGB: string;
  linkImprint: string;
  linkPrivacy: string;

  constructor(public checkout: EtsCheckoutService, private config: EtsConfigService) { }

  ngOnInit(): void {
    this.initStandardValues();
    this.getFooterInformations();
  }

  /**
   * Initalizes all member variables with their standard value
   *
   * @returns void
   */
  private initStandardValues(): void {
    this.globalConfig = this.config.getConfigObject();
    this.copyRightDate = new Date().getFullYear().toString();
    this.agb = new EtsAGB();
    this.agb.title = 'Allgemeine Geschäftsbedingungen';
    this.agb.content = '';
    this.overlayAGBHidden = false;

    this.impressum = new EtsImpressum();
    this.impressum.title = 'Impressum';
    this.impressum.content = '';
    this.overlayImprintHidden = false;

    this.datenschutz = new EtsDatenschutz();
    this.datenschutz.title = 'Datenschutz';
    this.datenschutz.content = '';
    this.overlayPrivacyHidden = false;

    this.ueberUns = new EtsUeberUns();
    this.ueberUns.title = '';
    this.ueberUns.content = '';

    this.showTopScroller = false;
  }

  /**
   * requests agb, privacy and imprint from API
   *
   * @returns void
   */
  private getFooterInformations(): void {
    this.config.getShopIdentifierSubject().subscribe((identifier: string) => {
      if (identifier !== null && identifier !== '') {
        this.logoHidden = this.globalConfig.misc.logoHidden;
        if (this.globalConfig.footer.useEtsAgbs >= 1) {
          this.checkout.getAgb().subscribe(
            data => {
              this.agb = data[0];
            },
            error => {
              console.log(error);
            }
          );
        } else if (this.globalConfig.footer.linkAGB !== '' && this.globalConfig.footer.useEtsAgbs === 0) {
          this.overlayAGBHidden = true;
          this.linkAGB = this.globalConfig.footer.linkAGB;
        } else {
          this.agb.content = this.globalConfig.footer.textagb;
          this.agb.content += this.globalConfig.footer.textagb2;
        }

        if (this.globalConfig.footer.useEtsImprint >= 1) {
          this.checkout.getImpressum().subscribe(
            data => {
              this.impressum = data;
            },
            error => {
              console.log(error);
            }
          );
        } else if (this.globalConfig.footer.linkImprint !== '' && this.globalConfig.footer.useEtsImprint === 0) {
          this.overlayImprintHidden = true;
          this.linkImprint = this.globalConfig.footer.linkImprint;
        } else {
          this.impressum.content = this.globalConfig.footer.textImprint;
        }

        if (this.globalConfig.footer.useEtsPrivacy >= 1) {
          this.checkout.getDatenschutz().subscribe(
            data => {
              this.datenschutz = data;
            },
            error => {
              console.log(error);
            }
          );
        } else if (this.globalConfig.footer.linkPrivacy !== '' && this.globalConfig.footer.useEtsPrivacy === 0) {
          this.overlayPrivacyHidden = true;
          this.linkPrivacy = this.globalConfig.footer.linkPrivacy;
        } else {
          this.datenschutz.content = this.globalConfig.footer.textPrivacy;
        }

        this.checkout.getUeberUns().pipe(take(1)).subscribe(
          data => {
            this.ueberUns = data;
          },
          error => {
            console.log(error);
          }
        );
      }
    });
  }

  /**
   * Opens given url in new Tab/Window
   * @param url
   * @returns void
   */
  openURL(url: string): void
  {
    window.open(url, '_blank');
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(): void {
    if (window.pageYOffset > 100) {
      this.showTopScroller = true;
    } else {
      this.showTopScroller = false;
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
