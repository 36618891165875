<section class="etsContentWrapper etsWrapper">
  <div class="cartPage">
    <div class="etsCaptionWrapper">
      <h1 class="etsSiteCaption">{{ 'basket.your_basket' | translate }}</h1>
    </div>
    <div class="flexTable listTable cartTable" data-flex-name="cart">
      <ngx-spinner [showSpinner]="basket.basketLoadingValue" type="ball-clip-rotate" size="medium">
        <p class="loading spinnerText">{{ 'please_wait' | translate }}</p>
      </ngx-spinner>
      @if ((basket.basketSubjectValue.items && basket.basketSubjectValue.items.length > 0)) {
        <div class="header cartNotEmpty">
          <div class="event">{{ 'basket.event' | translate }}</div>
          <div class="dateAndLocation">{{ 'basket.dateAndLocation' | translate }}</div>
          <div class="ticketInfo">{{ 'basket.ticketInfo' | translate }}</div>
          <div class="price">{{ 'basket.price' | translate }}</div>
        </div>
        <div class="etsContent clearfix">
          <div class="line" [style]="!item.showActions ? 'border-top: unset;' : ''" *ngFor="let item of basket.basketSubjectValue.items">

            <div class="eventPicture">
              <div class="image" [ngStyle]="{'background-image': 'url(' + item.imageUrl + ')'}"></div>
              @if (item.showActions) {
                <div class="actions">
                  @if (config.getConfigObject().detail.keepEventUrl && basket.urlToEventId(item.eventId) !== null) {
                    <a title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasketWithRedirect(item.deleteParam, basket.urlToEventId(item.eventId))" class="edit actionButton handled"><div>D</div></a>
                  } @else {
                    <a title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" [appEtsRouterLink]="[{name: embeddedIn, target: 'detail-' + item.eventId}]" class="edit actionButton handled"><div>D</div></a>
                  }
                  <a title="{{ 'basket.delete' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" class="trash actionButton handled"><div>A</div></a>
                </div>
              }
            </div>
            <div class="eventName">
              @if (config.getConfigObject().detail.keepEventUrl && basket.urlToEventId(item.eventId) !== null) {
                <a href="{{basket.urlToEventId(item.eventId)}}" class="etsTitle">{{item.title}}</a>
              } @else {
                <a [appEtsRouterLink]="[{name: embeddedIn, target: 'detail-' + item.eventId}]" class="etsTitle">{{item.title}}</a>
              }
              <div class="subTitle">{{item.subTitle}}</div>
            </div>
            <div class="dateAndLocation">
              @if (!item.hideDateFlag) {
                @if (item.showEventTime) {
                  <div class="date">{{item.date.split(' ')[0] | date: 'EE, dd.MM.yyyy':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}}{{item.date.split(',' )[1]}}</div>
                } @else {
                  <div class="date">{{item.date | date: 'EE, dd.MM.yyyy, H:mm':undefined:(globalConfig.misc.languageCode !== 'de') ? 'en' : 'de'}} {{config.getConfigObject().misc.languageCode === 'de' ? 'Uhr' : ''}}</div>
                }
              }
              <div class="location">{{item.location}}</div>
            </div>
            <div class="ticketInfo">
              <div class="quantity">{{'basket.amount' | translate}}: {{item.quantity}}</div>
              <div class="details">{{item.seatInfo}}</div>
              <div class="priceGroups">{{item.priceGroups}}</div>
            </div>
            <div class="price">{{item.price | currency}} €</div>
            @if (item.showActions) {
              <div class="actions">
                @if (config.getConfigObject().detail.keepEventUrl && basket.urlToEventId(item.eventId) !== null) {
                  <a title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasketWithRedirect(item.deleteParam, basket.urlToEventId(item.eventId))" class="edit actionButton handled"><div>D</div></a>
                } @else {
                  <a title="{{ 'basket.edit' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" [appEtsRouterLink]="[{name: embeddedIn, target: 'detail-' + item.eventId.toString().replaceAll('-', '777')}]" class="edit actionButton handled"><div>D</div></a>
                }
                <a title="{{ 'basket.delete' | translate }}" (click)="basket.deleteFromBasket(item.deleteParam)" class="trash actionButton handled"><div>A</div></a>
              </div>
            }
          </div>
          @for (item of basket.basketSubjectValue.chosenShipping; track $index) {
            <div class="line">
              <div class="amountCaption">
                {{ 'ticket.shippingFee' | translate }}
              </div>
              <div class="shippingMethodName alignRight">
                {{item.pricePlain | currency}}
              </div>
            </div>
          }
          @for (item of basket.basketSubjectValue.chosenShipping; track $index) {
            <div class="line">
              <div class="shippingType">
                {{ 'ticket.shippingMethod' | translate }}
              </div>
              <div class="shippingMethodName alignRight">
                {{item.title}}
              </div>
            </div>
          }
          <div class="line timeoutError cartNotEmpty" [class.off]="(basket.timerStringValue !== '00:00')">{{ 'basket.reservationTimeout' | translate }}</div>

          <div class="line cartNotEmpty">
            <div class="amountCaption">
              <strong>{{ 'ticket.totalPrice' | translate }}</strong> <span class="shippingInfo"><span *ngIf="basket.chosenShipping <= 0 || basket.chosenShipping === undefined || basket.chosenShipping === null">{{'general.plus' | translate}} </span><span *ngIf="basket.chosenShipping > 0">{{'general.including' | translate }} </span> <app-ets-overlay title="{{'checkout.basket.shippingHeadline' | translate}}" linkTitle="{{ 'ticket.shippingFee' | translate }}">
              <div class="scroller">
                <p class="description">{{ 'basket.shippingInformations' | translate }}<br>
                  {{ 'basket.shippingInformations2' | translate }}</p>
                <ul class="optionsList">
                  @for (shipping of basket.basketSubjectValue.shipping; track shipping.id) {
                    <li class="clearfix">
                      <div class="info">
                        <h3>{{shipping.title}}</h3>
                      </div>
                      <div class="price">{{shipping.price}}</div>
                    </li>
                  }
                </ul>
              </div>
              </app-ets-overlay>
              </span>
              <br>
              <span title="Verbleibende Reservierungszeit" class="countdown">
                <span class="label">{{ 'basket.remainingReservationTime' | translate }}: </span> <span class="time">{{basket.timerStringValue}}</span>
              </span>
            </div>
            <div class="price">
              <div class="totalPrice">{{ basket.basketSubjectValue.totalPrice | currency }} €</div>
              <div class="shippingInfo"><span *ngIf="basket.chosenShipping <= 0 || basket.chosenShipping === undefined || basket.chosenShipping === null">{{'general.plus' | translate}} </span><span *ngIf="basket.chosenShipping > 0">{{ 'general.including' | translate }}</span> <app-ets-overlay title="Unsere Versandgebühren" linkTitle="Versandgebühren">
                <div class="scroller">
                <p class="description">{{ 'ticket.shippingInformations' | translate }}<br>
                  {{ 'basket.shippingInformations2' | translate}}</p>
                <ul class="optionsList">
                  <li class="clearfix" *ngFor="let shipping of basket.basketSubjectValue.shipping">
                    <div class="info">
                      <h3>{{shipping.title}}</h3>
                    </div>
                    <div class="price">{{shipping.price}}</div>
                  </li>
                </ul>
                </div>
              </app-ets-overlay></div>
            </div>
          </div>
          @if (globalConfig.checkout.allowPromotioncodes) {
            <div class="promotionCode">
              <form (ngSubmit)="redeemPromoCode()" [formGroup]="promotionCodeForm" class="promotioncodeForm">
                @if (basket.basketSubjectValue.promotionCode) {
                  <div>
                    <div class="wrapper">
                      <input type="text" readonly [value]="'Rabattcode: ' + basket.basketSubjectValue.promotionCode">
                      <button class="etsBtn" type="button" (click)="removePromoCode()">Rabattcode entfernen</button>
                    </div>
                  </div>
                } @else {
                  <div class="wrapper">
                    <input type="text" id="promotioncode" name="promotioncode" formControlName="promotioncode" placeholder="{{'basket.inputPromotionCodePlaceholder' | translate}}">
                    <button class="etsBtn" type="submit" [disabled]="promotionCodeForm.controls.promotioncode?.errors !== null">{{'basket.btnRedeemCode' | translate}}</button>
                  </div>
                }

                @if (promotionCodeForm.controls.promotioncode?.errors) {
                  @if (promotionCodeForm.controls.promotioncode?.errors['minlength']) {
                    <div>
                      <p>{{'basket.promotioncodeMinLength' | translate}}</p>
                    </div>
                  }
                  @if (promotionCodeForm.controls.promotioncode?.errors['maxlength']) {
                    <div>
                      <p>{{'basket.promotioncodeMaxLength' | translate}}</p>
                    </div>
                  }
                }
              </form>
            </div>
          }
        </div>
      } @else {
        <div class="etsContent cartEmpty">
          <div class="line">
            <div class="notice">
              {{ 'basket.empty' | translate }}
            </div>
          </div>
        </div>
      }
    </div>
    <div class="clearfix basketButtonWrapper">
      @if (globalConfig.returnHomeUrl && globalConfig.returnHomeUrl.length > 0) {
        <ng-container>
          @if (basket?.basketSubjectValue?.items?.length > 0) {
            <a class="etsBtn cartNotEmpty " href="{{globalConfig.returnHomeUrl}}">{{ 'basket.moreEvents' | translate }}</a>
          } @else {
            <a class="etsBtn cartEmpty" href="{{globalConfig.returnHomeUrl}}">{{ 'basket.landingPage' | translate }}</a>
          }
        </ng-container>
      }

      @if (globalConfig.returnHomeRouting && globalConfig.returnHomeRouting.length > 0) {
        <ng-container>
          @if (basket?.basketSubjectValue?.items?.length > 0) {
            <a class="etsBtn cartNotEmpty " [appEtsRouterLink]="[{name: embeddedIn, target: globalConfig.returnHomeRouting}]">{{ 'basket.moreEvents' | translate }}</a>
          } @else {
            <a class="etsBtn cartEmpty" [appEtsRouterLink]="[{name: embeddedIn, target: globalConfig.returnHomeRouting}]">{{ 'basket.landingPage' | translate }}</a>
          }
        </ng-container>
      } @else {
        @if ((!globalConfig.returnHomeUrl || globalConfig.returnHomeUrl.length <= 0)) {
          <ng-container>
            @if (!(basket.basketSubjectValue.items && basket.basketSubjectValue.items.length > 0)) {
              <a class="etsBtn cartEmpty" (click)="(globalConfig.detail.useContinueShoppingUrl === 1 && globalConfig.detail.continueShoppingUrl.length > 0) ? redirectToUrl(globalConfig.detail.continueShoppingUrl) : router.resetToHome()">{{ 'basket.landingPage' | translate }}</a>
            } @else {
              <a class="etsBtn cartNotEmpty" (click)="(globalConfig.detail.useContinueShoppingUrl === 1 && globalConfig.detail.continueShoppingUrl.length > 0) ? redirectToUrl(globalConfig.detail.continueShoppingUrl) : router.resetToHome()">{{ 'basket.moreEvents' | translate }}</a>
            }
          </ng-container>
        }
      }
      &nbsp;
      @if (couponRequired && (basket.basketSubjectValue.items && basket.basketSubjectValue.items.length > 0 && !config.getConfigObject().detail.shopSiteUrl)) {
        <a title="couponcode" class="etsBtn cartNotEmpty submitButton moreOffers" [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-promotionCode'}]" (click)="doCheckout()">{{ 'basket.checkout' | translate }}</a>
      } @else {
        @if (basket.basketSubjectValue.items.length > 0) {
          @if (boschShop) {
            <!-- ! Special Case Boschshops -->
            <button title="login" [disabled]="processing" class="etsBtn cartNotEmpty submitButton moreOffers" (click)="doGuestLogin(); doCheckout()">{{ 'basket.checkout' | translate}}</button>
          } @else {
            <a title="login" class="etsBtn cartNotEmpty submitButton moreOffers" [appEtsRouterLink]="[{name: embeddedIn, target: 'checkout-login'}]"  (click)="doCheckout()">{{ 'basket.checkout' | translate}}</a>
          }
        }
      }
    </div>
  </div>
</section>
