import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ets-overlay',
  templateUrl: './ets-overlay.component.html',
  styleUrls: ['./ets-overlay.component.css']
})
export class EtsOverlayComponent implements OnInit {
  showModal: boolean;
  @Input() title: string;
  @Input() linkTitle: string;
  @Input() classList = '';

  constructor() { }

  ngOnInit(): void {
    this.showModal = false;
  }

  onClick(): void {
    this.showModal = true;
  }

  closeModal(): void {
    this.showModal = false;
  }

}
