@if (!globalConfig.misc.maintenance_mode_active) {
  <main id="main" class="etsShop">
    <app-ets-header></app-ets-header>
    @if (globalConfig.infofield.showHeaderInfoText && globalConfig.infofield.infotextHeader !== '') {
      <ng-container>
        <div class="headerInfoText" [innerHTML]="globalConfig.infofield.infotextHeader | allowHTML"></div>
      </ng-container>
    }
    <nav class="clearfix" id="navbar" (scroll)="navScroll()">
      <div class="flexbox">
        <div class="etsWrapper">
          @if (identifier === '') {
            <app-ets-search class="searchBar" embedName="page" identifier="searchIdentifier" style="visibility: {{searchbarVisible}} !important;" ></app-ets-search>
          }
        </div>
        <app-ets-menu-buttons></app-ets-menu-buttons>
      </div>
    </nav>
    <div class="breadcrumb etsWrapper">
    </div>
    <section class="etsContentWrapper etsWrapper additionalFooterHeight">
      <!-- Show body info text on all sites -->
      @if (globalConfig.infofield.showBodyInfoText && !globalConfig.infofield.showBodyTextIndexOnly && globalConfig.infofield.infotextBody !== '') {
        <ng-container>
          <div class="bodyInfoText" [innerHTML]="globalConfig.infofield.infotextBody | allowHTML"></div>
        </ng-container>
      }

      @if (identifier === '') {
        <ng-container>
          @if (globalConfig?.misc.showCalendar) {
            <ng-container *ngIf="globalConfig?.misc.showCalendar">
              <app-ets-calendar-outer embedName="page"></app-ets-calendar-outer>
            </ng-container>
          } @else {
            <ng-container *ngIf="!globalConfig?.misc.showCalendar">
              <app-ets-listing-outer embedName="page" identifier="mainShopKey"></app-ets-listing-outer>
            </ng-container>
          }
        </ng-container>
      } @else {
        <ng-container>
          <app-ets-detail-outer embedName="page" [identifier]="identifier"></app-ets-detail-outer>
        </ng-container>
      }
    </section>
    <app-ets-footer></app-ets-footer>
  </main>
} @else {
  <main>
    <div>
      <app-ets-maintenance-page/>
    </div>
  </main>
}
