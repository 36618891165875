<div class="etsShop" *ngIf="!configData.misc.maintenance_mode_active">
<ng-container *ngIf="!hasName()">
  Error name {{embedName}}
</ng-container>
<ng-container *ngIf="hasName() && embedName == 'subComponent'">
  <ng-content></ng-content>
</ng-container>
<ng-container *ngIf="currentRouteStructure != null && hasName() && embedName != 'subComponent'">
  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == ''">
    <ng-content></ng-content>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'detail' && currentRouteStructure[embedUrlName].params.length == 1">
    <app-ets-detail embedName="subComponent" [identifier]="currentRouteStructure[embedUrlName].params[0]" [showTitle]="configData.detail.showTitle"
                    [showHeadliner]="configData.detail.showHeadliner" [showInfoAccordion]="configData.detail.showInfoAccordion"
                    [showTicketing]="configData.detail.showTicketing" [embeddedIn]="embedName"></app-ets-detail>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'listing' && currentRouteStructure[embedUrlName].params.length == 1">
    <app-ets-listing embedName="subComponent" [identifier]="currentRouteStructure[embedUrlName].params[0]"></app-ets-listing>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'checkout' && currentRouteStructure[embedUrlName].params.length == 1">
    <app-ets-checkout embedName="subComponent" [embeddedIn]="embedName" [setStep]="currentRouteStructure[embedUrlName].params[0]"></app-ets-checkout>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'promotionCode' && currentRouteStructure[embedUrlName].params.length == 1">
    <app-ets-coupon embedName="subComponent" [embeddedIn]="embedName" [setStep]="currentRouteStructure[embedUrlName].params[0]"></app-ets-coupon>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'basket'">
    <app-ets-basket-detail embedName="subComponent" [identifier]="currentRouteStructure[embedUrlName].params[0]"
                      [embeddedIn]="embedName"></app-ets-basket-detail>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'profile'">
    <app-ets-profile embedName="subComponent" [identifier]="currentRouteStructure[embedUrlName].params[0]"
                           [embeddedin]="embedName"></app-ets-profile>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'menubuttons' && currentRouteStructure[embedUrlName].params.length == 0">
    <app-ets-basket-button embedName="subComponent"></app-ets-basket-button>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'ticket'">
    <app-ets-ticket embedName="subComponent" [embeddedIn]="embedName" [identifier]="currentRouteStructure[embedUrlName].params[0]"></app-ets-ticket>
  </ng-container>

  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'headliner'">
    <app-ets-headliner embedName="subComponent" [identifier]="currentRouteStructure[embedUrlName].params[0]"></app-ets-headliner>
  </ng-container>
  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'calticket' && currentRouteStructure[embedUrlName].params.length == 1">
    <app-ets-detail embedName="subComponent" [identifier]="currentRouteStructure[embedUrlName].params[0]" [showTitle]="true"
                    [showHeadliner]="false" [showInfoAccordion]="false"
                    [showTicketing]="true" [embeddedIn]="embedName"></app-ets-detail>
  </ng-container>
  <ng-container *ngIf="currentRouteStructure[embedUrlName].currentComponent == 'searchresult'">
    <app-ets-search-results [embedName]="currentRouteStructure[embedUrlName].embedName" [identifier]="currentRouteStructure[embedUrlName].params[0]" [embeddedIn]="embedName"></app-ets-search-results>
  </ng-container>

</ng-container>
</div>
<div *ngIf="configData.misc.maintenance_mode_active">
  <app-ets-maintenance-page/>
</div>
