import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../base-component/base-component';
import {EtsBasketService} from '../ets-basket-service/ets-basket-service';
import {EtsLoginService} from '../ets-login-service/ets-login-service';
import {EtsComponentRouterService} from '../ets-component-router-service/ets-component-router.service';
import {ToastrService} from 'ngx-toastr';
import {EtsConfigService} from '../ets-config-service/ets-config.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { EtsCheckoutService } from '../ets-checkout-service/ets-checkout-service';
import { EtsCouponData } from '../ets-coupon-service/ets-coupon-data';
import { EtsCouponService } from '../ets-coupon-service/ets-coupon-service';
import { SpecialrequestsService } from '../services/specialrequests.service';

@Component({
  selector: 'app-ets-menu-buttons',
  templateUrl: './ets-menu-buttons.component.html',
  styleUrls: ['./ets-menu-buttons.component.css']
})
export class EtsMenuButtonsComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('etsWidgetButtonsId') widgetButton: ElementRef;
  @ViewChild('etsUserFlyout') userFlyout: ElementRef;
  @ViewChild('etsBasketFlyout') basketFlyout: ElementRef;
  @Input() mode: string;
  @Input() embedName: string;
  @Input() identifier: string;
  @Input() redirectprofile: string;
  @Input() redirectbasket: string;
  loginPassword: string;
  loginUser: string;
  loginInProgress: boolean;
  loginStatus: boolean;
  showLogin: boolean;
  showBasket: boolean;
  isGuest: boolean;
  detailIdentifier: string;
  couponSettings: EtsCouponData;
  couponRequired: boolean;
  globalConfig;
  showEventTime: any;
  calcReady: boolean;
  userFlyoutToggle: boolean;
  basketAmount;
  shippingMethod: number;
  boschShop: boolean

  constructor(
    public basket: EtsBasketService,
    public login: EtsLoginService,
    private router: EtsComponentRouterService,
    private toastr: ToastrService,
    private config: EtsConfigService,
    private translate: TranslateService,
    private location: Location,
    private checkoutService: EtsCheckoutService,
    private couponService: EtsCouponService,
    public specialRequestService: SpecialrequestsService
  ) {
    super();

    this.shippingMethod = null;
    this.showLogin = false;
    this.showBasket = false;
    this.isGuest = false;
  }

  ngOnInit(): void {
    this.loginStatus = this.login.isLoggedInValue;
    this.userFlyoutToggle = false;
    if (this.mode === 'login') {
      this.showLogin = true;
    } else if (this.mode === 'basket') {
      this.showBasket = true;
    } else {
      this.showLogin = true;
      this.showBasket = true;
    }
    this.checkoutService.reloadShippingMethod();
    const shippingDetails = JSON.parse(localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()));

    if (shippingDetails !== undefined && shippingDetails !== null) {
      this.shippingMethod = shippingDetails.chosenShipping[0].id;
    }
    this.loginStatus = this.login.isLoggedInValue;
    this.userFlyoutToggle = false;
    this.detailIdentifier = this.config.getConfigObject().detail.mainComponent;
    this.translate.use(this.config.getConfigObject().misc.languageCode);

    if (!this.identifier) {
      this.identifier = this.config.getConfigObject().detail.mainComponent;
    }

    this.config.getShopIdentifierSubject().subscribe( (identifier) => {
      // ! Special Case Bosch shops.
      switch(identifier) {
        case '8284e02215089cb7d8cf019e5697c390':
        case '53a8edde17f2780485ccbdf81eb74e64':
        case 'f182d9ad06e9bda677d7a3046a0dc7ab':
        case '5306f1fd3425bbd1907c34d28fa82da3':
        case 'e26ba42994bef547cee9e624bb48dfe6':
          this.boschShop = true;
          break;
        default:
          this.boschShop = false;
          break;
      }
      this.globalConfig = this.config.getConfigObject();
      if (localStorage.getItem('ets-eventTime-' + this.config.getShopIdentifier()) !== undefined && localStorage.getItem('ets-eventTime-' + this.config.getShopIdentifier()) !== null) {
        this.showEventTime = JSON.parse(localStorage.getItem('ets-eventTime-' + this.config.getShopIdentifier()));
      }
      setTimeout(() => {
        this.checkCouponRequirement();
      }, 500);

    });
  }

  /**
   * Updates guest flag which indicates if user is a guest,
   * instead of a regular registered user. Depending on this
   * flags state, some things change in this component.
   *
   * @returns void
   */
  updateGuestState(): void {
    if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier())) {
      this.isGuest = true;
    } else if (this.login.isGuest.value) {
      this.isGuest = this.login.isGuest.value;
    } else {
      this.isGuest = false;
    }
  }

  ngDoCheck(): void {

    const shippingDetails = JSON.parse(localStorage.getItem('ets-basket-' + this.config.getShopIdentifier()));
    if (shippingDetails !== undefined && shippingDetails !== null) {
      if (shippingDetails.chosenShipping) {
        if (shippingDetails.chosenShipping.length > 0) {
          if (shippingDetails.chosenShipping[0].id) {
            this.shippingMethod = shippingDetails.chosenShipping[0].id;
            this.basket.chosenShipping = shippingDetails.chosenShipping[0].id;
          }
        } else {
          this.basket.chosenShipping = null;
        }
      }

    }

    this.updateGuestState();
  }

  /**
   * Customer login method
   *
   * @returns void
   */
  doLogin(): void {
    if (this.loginPassword.length > 0 && this.loginUser.length > 0) {
      this.loginInProgress = true;
      this.login.doLogin(this.loginUser, this.loginPassword).subscribe(() => {

        this.loginUser = '';
        this.loginPassword = '';
        this.loginInProgress = false;
        this.router.navigateEmbed([{name: this.detailIdentifier, target: 'profile-profile'}]);
      }, error => {
        console.log(error);

        if (error.status === 403) {
          this.toastr.error(this.translate.instant('toastr.login.errorBlocked'));
        } else {
          this.toastr.error(this.translate.instant('toastr.login.error'));
        }
        this.loginPassword = '';
        this.loginInProgress = false;
      });
    } else {
      this.toastr.error(this.translate.instant('ets-menu-buttons.login.errorEmptyFields'));
    }
  }

  /**
   * customer logout method
   *
   * @returns void
   */
  doLogout(): void {
    if (this.login.isLoggedInValue) {
      this.login.doLogout().subscribe(
        () => {
          this.router.resetToHome();
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  calcIsLeft(): boolean {
    if (this.calcReady) {
      const rect = document.getElementsByTagName('body')[0].getBoundingClientRect();
      return this.widgetButton.nativeElement.getBoundingClientRect().x < Math.round(rect.width / 2.0);
    } else {
      return true;
    }
  }

  showUserFlyout(): boolean {
    return this.userFlyoutToggle;
  }

  toggleUserFlyout(): void
  {
    if (this.userFlyoutToggle) {
      this.userFlyoutToggle = false;
    } else {
      this.userFlyoutToggle = true;
    }
  }

  showUserFlyoutEnter(): void {
    const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;

    if (!isMobile) {
      this.userFlyoutToggle = true;
    }
  }

  showUserFlyoutLeave($event): void {

    if ($event.target.tagName.toLowerCase() !== 'input') {
      this.userFlyoutToggle = false;
    }
  }

  ngAfterViewInit(): void {
    this.calcReady = true;
    if (this.showLogin === false || this.showBasket === false) {
      this.widgetButton.nativeElement.style.width = '100px';

      if (this.showLogin === false) {
        this.basketFlyout.nativeElement.style.width = '100%';
      }

      if (this.showBasket === false) {
        this.userFlyout.nativeElement.style.width = '100%';
      }
    }
  }

  routeClick($event): void {
    if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier())) {
      if (localStorage.getItem('ets-isGuestOrder-' + this.config.getShopIdentifier()) === '1') {
        return;
      }
    }
    if ($event.target.classList.contains('etsButton')) {
      if (this.login.isLoggedInValue) {
        this.router.navigateEmbed([{name: this.identifier, target: 'profile-profile', base: this.redirectprofile}]);
      }
    }
  }

  goBack(): void {
    this.location.back();
  }

  setCheckout(): void {
    this.basket.isCheckout = true;
  }

  /**
   * Checks if a promotion code is needed
   *
   * @returns void
   */
   checkCouponRequirement(): void {
    this.config.getShopIdentifierSubject().subscribe(() => {
      this.couponService.getCouponCodeSettings(this.config.getShopIdentifier()).subscribe( data => {
        this.couponSettings = data;
        this.couponRequired = data['coupons'].required;
      },
      error => {
        console.log(error);
      });
    });
  }

  /**
   * Redirects user to desired url
   * @param url string
   */
  redirectToUrl(url: string): void
  {
    window.location.href = url;
  }

  public doGuestLogin(): void
  {
    this.login.doGuestLogin().subscribe( () => {
        this.router.navigateEmbed([{name: this.identifier, target: 'checkout-address'}]);
    });
  }
}
