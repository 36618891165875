import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-base-component',
  template: ''
})
export class BaseComponent {
  @Input() embedName: string;

  isInputTrue(input: string | null | undefined, undefinedTrue = true): boolean {
    if (input === undefined) {
      // Undefiniert heißt meistens, dass ein Parameter nicht explizit gesetzt wurde. Auf True defaulten.
      return undefinedTrue;
    } else if (input === null) {
      return false;
    }

    return input.toString().toLocaleLowerCase() == 'true' || input.toString().toLocaleLowerCase() === '1';
  }
}
