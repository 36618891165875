import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorcodeService {

  constructor(private translateService: TranslateService) { }

  /**
   * Return determined errormessage based on service and errorcode.
   *
   * @param service
   * @param errorCode
   * @returns
   */
  public getErrorMessage(service: string, errorCode: string): string
  {
    switch(service) {
      case 'login':
        return this.getLoginErrorMessage(errorCode);
      case 'shippingmethod':
        return this.getShippingErrorMessage(errorCode);
      case 'promotioncode':
        return this.getPromotioncodeErrors(errorCode);
      default:
        return this.translateService.instant('general.genericError');
    }
  }

  /**
   * Returns login related errormessages.
   *
   * @param errorCode
   * @returns
   */
  private getLoginErrorMessage(errorCode: string): string
  {
    switch(errorCode) {
      case 'OrdC1':
        return this.translateService.instant('errorMessage.ticketing.OrdC1');
      case 'OrdC2':
        return this.translateService.instant('errorMessage.ticketing.OrdC2');
      default:
        return this.translateService.instant('toastr.checkout.setshippingError');
    }
  }

  /**
   * Returns shipping method related errormessages.
   *
   * @param errorCode
   * @returns
   */
  private getShippingErrorMessage(errorCode: string): string
  {
    switch(errorCode) {
      case 'OrdC1':
        return this.translateService.instant('errorMessage.ticketing.OrdC1');
      case 'OrdC2':
        return this.translateService.instant('errorMessage.ticketing.OrdC2');
      default:
        return this.translateService.instant('toastr.checkout.setshippingError');
    }
  }

  /**
   * Returns translation for given promotioncode error.
   *
   * @param state
   * @param errorCode
   * @returns string
   */
  private getPromotioncodeErrors(errorCode: string): string
  {
    switch(errorCode) {
      case 'Promo1': {
        return this.translateService.instant('errorMessage.promotioncodes.Promo1');
      }
      case 'Promo2': {
        return this.translateService.instant('errorMessage.promotioncodes.Promo2');
      }
      case 'Promo3': {
        return this.translateService.instant('errorMessage.promotioncodes.Promo3');

      }
      case 'Promo4': {
        return this.translateService.instant('errorMessage.promotioncodes.Promo4');
      }
      case 'Promo6': {
        return this.translateService.instant('errorMessage.promotioncodes.Promo6');
      }
      case 'Promo7': {
        return this.translateService.instant('errorMessage.promotioncodes.Promo7');
      }
      default:
        return this.translateService.instant('errorMessage.promotioncodes.general');
    }
  }
}
