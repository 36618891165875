export class EtsBasketData {
  items: {
    title: string,
    organizer: {
      id: number,
      name: string,
    },
    subTitle: string,
    date: string,
    location: string,
    price: string,
    priceItem: string,
    imageUrl: string,
    quantity: string,
    details: string,
    priceGroups: string,
    seatNos: string[],
    eventId: number,
    eventIds: number[],
    seatInfo: string,
    personalization: string[]
    deleteParam: string,
    showEventTime: boolean;
    hideDateFlag: boolean;
    showActions: boolean;
  }[];
  shipping: {
    id: number,
    title: string,
    description: string,
    price: string,
    pricePlain: number,
    type: string
  }[];
  chosenShipping: {
    id: number,
    title: string,
    description: string,
    price: string,
    pricePlain: number,
    type: string
  }[];
  chosenPayment: {
  }[];
  totalPrice: number;
  timerEnd: number;
  promotionCode: string;
}

export class EtsAddBasketBestSeat {
  id: string;
  amount: number;
}

export class EtsAddBasketData {
  status: string;
  guest: boolean;
  token: string;
  errors: string;
  message: string;
}

export class EtsChosenShipping {
  id: number;
  title: string;
  description: string;
  price: string;
  pricePlain: number;
  type: string;
}

export class EtsBasketUrl {
  eventId: number;
  url: string;
}
